import {
  MdOutlineSupervisedUserCircle,
  MdPersonAddAlt,
  MdOutlineTaxiAlert,
  MdFormatListBulleted,
  MdListAlt,
  MdInsertChartOutlined,
  MdBusiness,
  MdOutlineAdminPanelSettings,
  MdAutorenew,
  MdOutlineBarChart,
  MdOutlineSms,
  MdOutlineDashboard,
  MdOutlinePersonOutline,
  MdOutlineAccountBalanceWallet,
  MdOutlineAttachMoney,
  MdOutlineAllInbox,
  MdOutlineDirectionsCar,
  MdOutlineLocalGroceryStore,
} from 'react-icons/md';
import { TbApps } from 'react-icons/tb';
import { AuthorizePermission } from '@tenant/utility/constants/authorizePermission';

const routesConfig = [
  {
    id: 'administrators',
    title: 'Administrators',
    messageId: 'Administrators',
    type: 'group',
    url: '/dashboards/analytics',
    isPermissionManager: true,
    children: [
      {
        id: 'permissions',
        title: 'Permissions',
        messageId: 'Permissions',
        icon: <MdOutlineAdminPanelSettings />,
        type: 'item',
        url: '/admin/permissions',
        isEnd: true,
      },
    ],
  },
  {
    id: 'analytics',
    title: 'Analytics',
    messageId: 'Analytics',
    type: 'group',
    url: '/dashboards/analytics',
    children: [
      {
        id: 'Dashboard',
        title: 'Dashboard',
        messageId: 'dashboard',
        type: 'item',
        url: '/dashboards/analytics',
        permissionIds: [AuthorizePermission['Analytic - View']],
        icon: <MdOutlineBarChart />,
      },
      {
        id: 'Customer',
        title: 'Customer',
        messageId: 'customer',
        type: 'item',
        url: '/dashboards/customer',
        icon: <MdOutlinePersonOutline />,
      },
      {
        id: 'Finance',
        title: 'Finance',
        messageId: 'finance',
        type: 'item',
        url: '/dashboards/finance',
        icon: <MdOutlineAttachMoney />,
      },
      {
        id: 'Point',
        title: 'Point',
        messageId: 'point',
        icon: <MdOutlineAccountBalanceWallet />,
        type: 'item',
        url: '/point-dashboards',
      },
      {
        id: 'im',
        title: 'Twilio Conversation',
        messageId: 'twilio',
        type: 'item',
        url: '/dashboards/twilio-conversation',
        permissionIds: [AuthorizePermission['Analytic - View']],
        icon: <MdOutlineSms />,
      },
      {
        id: 'vehicleTracking',
        title: 'Vehicle Tracking',
        messageId: 'vehicletracking',
        type: 'item',
        url: '/dashboards/vehicle-tracking/30',
        permissionIds: [AuthorizePermission['Analytic - View']],
        icon: <MdOutlineDirectionsCar />,
      },
      {
        id: 'features',
        title: 'Features',
        messageId: 'Features',
        type: 'collapse',
        icon: <MdOutlineAllInbox />,
        permissionIds: [AuthorizePermission['Analytic - View']],
        children: [],
      },
      {
        id: 'marketplace',
        title: 'Marketplace',
        messageId: 'marketplace.header',
        type: 'item',
        url: '/dashboards/marketplace',
        permissionIds: [AuthorizePermission['Analytic - View']],
        icon: <MdOutlineLocalGroceryStore />,
      },
    ],
  },
  {
    id: 'app',
    title: 'Tenant',
    messageId: 'Tenant',
    type: 'group',
    children: [
      {
        id: 'tenant',
        title: 'Tenant',
        messageId: 'Tenant',
        type: 'item',
        icon: <MdOutlineSupervisedUserCircle />,
        url: '/tenant',
        isEnd: true,
      },
      {
        id: 'company',
        title: 'Company',
        messageId: 'company.header',
        type: 'item',
        icon: <MdBusiness />,
        url: '/company',
        isEnd: true,
      },
    ],
  },
  {
    id: 'apps',
    title: 'Apps',
    messageId: 'sidebar.apps',
    type: 'group',
    children: [
      {
        id: 'marketplace',
        title: 'marketplace',
        messageId: 'marketplace',
        icon: <TbApps />,
        type: 'collapse',
        children: [
          {
            id: 'appsRequest',
            title: 'Apps Request',
            messageId: 'marketplace.appsRequest',
            type: 'item',
            url: '/apps/marketplace/request',
          },
        ],
      },
      {
        id: 'bundleOrders',
        title: 'Bundle Orders',
        messageId: 'bundle.ordersList',
        type: 'item',
        icon: <MdListAlt />,
        color: '#48bb78',
        url: '/apps/bundle-orders/all',
      },
      {
        id: 'productUsage',
        title: 'Professional Services',
        messageId: 'productUsage.header',
        type: 'item',
        icon: <MdFormatListBulleted />,
        color: '#48bb78',
        url: '/apps/product-usage/all',
      },
      {
        id: 'subcontractorWorkflow',
        title: 'Subcontractor',
        messageId: 'subcontractorWorkflow',
        type: 'collapse',
        icon: <MdPersonAddAlt />,
        children: [
          {
            id: 'automateSubcontractor',
            title: 'Setup Request',
            messageId: 'automateSubcontractor.header',
            type: 'item',
            color: '#48bb78',
            url: '/apps/subcontractor-automate/all',
          },
          {
            id: 'manageMapping',
            title: 'Management',
            messageId: 'manageMapping.header',
            type: 'item',
            color: '#48bb78',
            url: '/apps/mapping-manage/all',
          },
        ],
      },
      {
        id: 'vehicleTracking',
        title: 'Vehicle Tracking',
        messageId: 'vehicletracking',
        type: 'collapse',
        icon: <MdOutlineTaxiAlert />,
        children: [
          {
            id: 'request',
            title: 'Request',
            messageId: 'vehicleTrackingRequest.header',
            type: 'item',
            url: '/vehicle-tracking/request',
          },
          {
            id: 'devicesInactive',
            title: 'In Stock Devices',
            messageId: 'vehicleTrackingInfo.header2',
            type: 'item',
            url: '/vehicle-tracking/device?inStock=true',
          },
          {
            id: 'info',
            title: 'In Use Devices',
            messageId: 'vehicleTrackingInfo.header',
            type: 'item',
            url: '/vehicle-tracking/info',
          },
        ],
      },
      {
        id: 'migrateTenant',
        title: 'Migrate Tenant',
        messageId: 'migrateTenant.header',
        type: 'collapse',
        icon: <MdAutorenew />,
        children: [
          {
            id: 'migrateTenantSetup',
            title: 'Setup',
            messageId: 'migrateTenant.setup.header',
            type: 'item',
            url: '/apps/migrate-tenant/all/add',
            isEnd: true,
          },
          {
            id: 'migrateTenantList',
            title: 'Request',
            messageId: 'migrateTenant.list.header',
            type: 'item',
            url: '/apps/migrate-tenant/all',
            isEnd: true,
          },
        ],
      },
    ],
  },
];

export default routesConfig;
