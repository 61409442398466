import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Field, FieldArray } from 'formik';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Tooltip,
  ListItemIcon,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';

import AppLoader from '@tenant/core/AppLoader';
import TableSkeleton from 'pages/apps/components/TableSkeleton';
import AppGridContainer from '@tenant/core/AppGridContainer';
import IntlMessages from '@tenant/utility/IntlMessages';
import useTenant from 'pages/apps/hooks/useTenant';
import {
  DISABLED_BUNDLE_NEXT_STEP,
  SET_RECOMMENDED_BUNDLES,
} from 'shared/constants/ActionTypes';
import FormBundle from './FormBundle';
import {
  parseCreditAmount,
  parseDebitAmount,
} from 'pages/apps/ProductUsage/helpers';
import { ReactComponent as EmptyIcon } from 'assets/icon/empty.svg';
import { numberToCurrency } from '@tenant/utility/helper/Utils';
import useBundle from 'pages/tenantDetail/hooks/useBundle';
import {
  calculateTotalPayment,
  calculateTaxAmount,
} from 'pages/tenantDetail/helpers/Bundles';
import useCompany from 'pages/apps/Company/hooks/useCompany';

const defaultBundle = {
  Id: null,
  Name: '',
  Points: 0,
  Price: 0,
  SelectedBundle: null,
  BasePrice: 0,
  Note: '',
  DiscountPercent: 0,
  DiscountedPrices: 0,
  Quantity: 1,
  BundleTotalPrices: 0,
  BundleTotalPoints: 0,
};

const StepSelectBundle = ({ values, setFieldValue }) => {
  const { loadingPage } = useTenant();
  const { storageRecommendedBundles } = useSelector(({ bundle }) => bundle);
  const { contractSummary } = useSelector(({ company }) => company);
  const { id, contractId } = useParams();
  const dispatch = useDispatch();
  const { loadingTableBundles, getRecommendedBundlesByTenant } = useBundle();
  const [recommendedBundles, setRecommendedBundles] = useState(
    storageRecommendedBundles,
  );
  const { getContractSummary, loadingContractSummary } = useCompany();

  const allBundles = useMemo(
    () => [...recommendedBundles, ...values?.selectedBundles] || [],
    [values?.selectedBundles, recommendedBundles],
  );

  const disableAddNew = useMemo(
    () => allBundles.some((e) => e.IsEdit),
    [allBundles],
  );

  // Un-hide this function when implement the recommend bundles feature
  // const getRecommendedBundles = useCallback(() => {
  //   if (!!recommendedBundles.length) return;

  //   getRecommendedBundlesByTenant(id, (data) => {
  //     setRecommendedBundles(data);
  //   });
  // }, [id, recommendedBundles]);

  const getContractInfo = useCallback(() => {
    if (!!contractSummary) return;

    getContractSummary(contractId);
  }, [contractId, contractSummary]);

  useEffect(() => {
    dispatch({
      type: DISABLED_BUNDLE_NEXT_STEP,
      payload: !allBundles.length || allBundles.some((b) => b.IsEdit),
    });
    setFieldValue('allBundles', allBundles);
  }, [allBundles]);

  useEffect(() => {
    const totalPoints = allBundles.reduce((total, bundle) => {
      return total + parseFloat(bundle.Points * bundle.Quantity);
    }, 0);

    const totalPrices = allBundles.reduce((total, bundle) => {
      return total + parseFloat(bundle.Price * bundle.Quantity);
    }, 0);

    const discountedTotalPrices = allBundles.reduce((total, bundle) => {
      return Math.round((total + bundle.DiscountedPrices) * 100) / 100;
    }, 0);

    const totalDiscount =
      Math.round((totalPrices - discountedTotalPrices) * 100) / 100;

    const taxAmount = calculateTaxAmount(
      discountedTotalPrices,
      contractSummary?.TaxValue,
    );

    const totalPayment = calculateTotalPayment(
      discountedTotalPrices,
      taxAmount,
    );

    setFieldValue('totalPurchasedPoints', totalPoints);
    setFieldValue('totalPrices', totalPrices);
    setFieldValue('discountedTotalPrices', discountedTotalPrices);
    setFieldValue('totalDiscount', totalDiscount);
    setFieldValue('totalPayment', totalPayment);
    setFieldValue('taxAmount', taxAmount);
  }, [allBundles, contractSummary]);

  useEffect(() => {
    if (!contractId) return;

    getContractInfo();
  }, [contractId]);

  return (
    <>
      {(loadingPage || loadingContractSummary) && <AppLoader />}
      <AppGridContainer spacing={5}>
        <Grid item xs={12} sm={9} md={9} sx={{ textAlign: 'right' }}>
          <FieldArray
            name='selectedBundles'
            render={({ remove, push, replace }) => (
              <>
                {!!allBundles.length ? (
                  <>
                    <TableContainer
                      sx={{ height: 'calc(100vh - 450px)', overflow: 'auto' }}
                    >
                      <Table sx={{ minWidth: 650 }} stickyHeader>
                        <TableHead>
                          <TableRow sx={{ verticalAlign: 'bottom' }}>
                            <TableCell>Bundle</TableCell>
                            <TableCell align='right'>Points</TableCell>
                            <TableCell align='right'>Prices</TableCell>
                            <TableCell align='right'>Quantity</TableCell>
                            <TableCell align='right'>Total Points</TableCell>
                            <TableCell align='right'>Total Prices</TableCell>
                            <TableCell align='right'>
                              Discount Percent
                            </TableCell>
                            <TableCell align='right'>Actions</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {loadingTableBundles ? (
                            <TableSkeleton rowsNum={3} cellsNum={3} />
                          ) : (
                            <>
                              {!!recommendedBundles.length && (
                                <>
                                  {recommendedBundles.map((bundle, idx) => (
                                    <FormBundle
                                      isEdit={bundle.IsEdit}
                                      bundle={bundle}
                                      key={bundle.id}
                                      handleSubmit={(data) => {
                                        const newList = recommendedBundles.map(
                                          (b) => {
                                            if (b.id === data.id) {
                                              return {
                                                ...data,
                                                IsEdit: false,
                                              };
                                            }
                                            return b;
                                          },
                                        );
                                        setRecommendedBundles(newList);
                                        dispatch({
                                          type: SET_RECOMMENDED_BUNDLES,
                                          payload: newList,
                                        });
                                      }}
                                      handleRemoveProduct={() => {
                                        const newList =
                                          recommendedBundles.filter(
                                            (b) => b.id !== bundle.id,
                                          );
                                        setRecommendedBundles(newList);
                                        dispatch({
                                          type: SET_RECOMMENDED_BUNDLES,
                                          payload: newList,
                                        });
                                      }}
                                      handleEditProduct={(data) => {
                                        const newList = recommendedBundles.map(
                                          (b) => {
                                            if (b.id === data.id) {
                                              return {
                                                ...b,
                                                IsEdit: true,
                                                SelectedBundle: data,
                                              };
                                            }
                                            return b;
                                          },
                                        );
                                        setRecommendedBundles(newList);
                                        dispatch({
                                          type: SET_RECOMMENDED_BUNDLES,
                                          payload: newList,
                                        });
                                      }}
                                    />
                                  ))}
                                </>
                              )}
                              {values.selectedBundles.map((bundle, idx) => (
                                <FormBundle
                                  isEdit={bundle.IsEdit}
                                  bundle={bundle}
                                  key={bundle.id}
                                  handleSubmit={(data) =>
                                    replace(idx, { ...data, IsEdit: false })
                                  }
                                  handleRemoveProduct={() => {
                                    remove(idx);
                                  }}
                                  handleEditProduct={(data) => {
                                    replace(idx, {
                                      ...data,
                                      IsEdit: true,
                                    });
                                  }}
                                />
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Button
                      sx={{ mt: 2 }}
                      variant='contained'
                      onClick={() =>
                        push({ ...defaultBundle, IsEdit: true, id: uuidv4() })
                      }
                      startIcon={<AddCircleOutlineIcon />}
                      disabled={disableAddNew}
                    >
                      <IntlMessages id='bundle.addBundle' />
                    </Button>
                  </>
                ) : (
                  <Stack
                    direction='column'
                    spacing={2}
                    justifyContent='center'
                    alignItems='center'
                    mt={3}
                  >
                    <EmptyIcon width={150} height={150} />
                    <Button
                      variant='contained'
                      onClick={() =>
                        push({ ...defaultBundle, IsEdit: true, id: uuidv4() })
                      }
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      <IntlMessages id='bundle.addBundle' />
                    </Button>
                  </Stack>
                )}
              </>
            )}
          />
        </Grid>

        {/* Balance Summary */}
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          sx={{
            height: 'calc(100vh - 360px)',
            overflow: 'auto',
          }}
        >
          <List dense>
            <ListItem sx={{ alignItems: 'baseline', gap: 4 }}>
              <ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  <IntlMessages id='common.company' />
                </Typography>
              </ListItemIcon>
              <Tooltip
                title={contractSummary?.CompanyName || '-'}
                placement='top'
              >
                <ListItemText
                  primary={contractSummary?.CompanyName || '-'}
                  primaryTypographyProps={{
                    sx: {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontSize: '15px',
                      textAlign: 'right',
                    },
                  }}
                />
              </Tooltip>
            </ListItem>

            <ListItem sx={{ alignItems: 'baseline', gap: 4 }}>
              <ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  <IntlMessages id='bundleContract.contract' />
                </Typography>
              </ListItemIcon>
              <Tooltip
                title={contractSummary?.ContractName || '-'}
                placement='top'
              >
                <ListItemText
                  primary={contractSummary?.ContractName || '-'}
                  primaryTypographyProps={{
                    sx: {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontSize: '15px',
                      textAlign: 'right',
                    },
                  }}
                />
              </Tooltip>
            </ListItem>

            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  <IntlMessages id='productForm.balance' />
                </Typography>
              </ListItemIcon>
              <ListItemText
                primary={contractSummary?.PointsBalance ?? 0}
                primaryTypographyProps={{
                  sx: {
                    fontSize: '15px',
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'primary.main',
                  },
                }}
              />
            </ListItem>

            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  <IntlMessages id='lockedPoints' />
                </Typography>
              </ListItemIcon>
              <ListItemText
                primary={contractSummary?.LockPoints ?? 0}
                primaryTypographyProps={{
                  sx: {
                    fontSize: '15px',
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'warning.main',
                  },
                }}
              />
            </ListItem>

            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  <IntlMessages id='common.country' />
                </Typography>
              </ListItemIcon>
              <Tooltip title={contractSummary?.Country || '-'} placement='top'>
                <ListItemText
                  primary={contractSummary?.Country || '-'}
                  primaryTypographyProps={{
                    sx: {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontSize: '15px',
                      textAlign: 'right',
                    },
                  }}
                />
              </Tooltip>
            </ListItem>
            <Divider component='li' />

            {/* Total Purchased Points */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  <IntlMessages id='bundle.totalPurchasedPoints' />
                </Typography>
              </ListItemIcon>
              <ListItemText
                primary={values?.totalPurchasedPoints ?? 0}
                primaryTypographyProps={{
                  sx: {
                    fontSize: '15px',
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'success.main',
                  },
                }}
              />
            </ListItem>

            {/* Total Prices */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  <IntlMessages id='bundleContract.totalPrices' />
                </Typography>
              </ListItemIcon>
              <ListItemText
                primary={numberToCurrency(values?.totalPrices ?? 0, 2, 'GBP')}
                primaryTypographyProps={{
                  sx: {
                    fontSize: '15px',
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'primary.main',
                  },
                }}
              />
            </ListItem>

            {/* Total Discount */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  <IntlMessages id='bundle.totalDiscount' />
                </Typography>
              </ListItemIcon>
              <ListItemText
                primary={parseDebitAmount(values?.totalDiscount ?? 0)}
                primaryTypographyProps={{
                  sx: {
                    fontSize: '15px',
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'primary.main',
                  },
                }}
              />
            </ListItem>

            <Divider component='li' />

            {/* Subtotal */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  <IntlMessages id='bundle.subTotal' />
                </Typography>
              </ListItemIcon>
              <ListItemText
                primary={numberToCurrency(
                  values?.discountedTotalPrices ?? 0,
                  2,
                  'GBP',
                )}
                primaryTypographyProps={{
                  sx: {
                    fontSize: '15px',
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'primary.main',
                  },
                }}
              />
            </ListItem>

            {/* Tax */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  <IntlMessages
                    id='bundle.countryTax'
                    values={{
                      tax: (
                        <Typography
                          component={'span'}
                          variant='subtitle1'
                          fontStyle={'italic'}
                          fontWeight={500}
                        >
                          {contractSummary?.TaxValue ?? 0}
                        </Typography>
                      ),
                    }}
                  />
                </Typography>
              </ListItemIcon>
              <ListItemText
                primary={parseCreditAmount(values?.taxAmount) ?? 0}
                primaryTypographyProps={{
                  sx: {
                    fontSize: '15px',
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'error.main',
                  },
                }}
              />
            </ListItem>

            <Divider component='li' />
            {/* Total Payment */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                  variant='h4'
                >
                  <IntlMessages id='bundle.total' />
                </Typography>
              </ListItemIcon>
              <ListItemText
                primary={numberToCurrency(values?.totalPayment ?? 0, 2, 'GBP')}
                primaryTypographyProps={{
                  sx: {
                    fontSize: '16px',
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'primary.main',
                  },
                }}
              />
            </ListItem>
          </List>
        </Grid>
      </AppGridContainer>
    </>
  );
};

export default React.memo(StepSelectBundle);

StepSelectBundle.propTypes = {};
