import React from 'react';
import IconButton from '@mui/material/IconButton';
import BuildIcon from '@mui/icons-material/Build';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventIcon from '@mui/icons-material/Event';
import FactoryIcon from '@mui/icons-material/Factory';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import { blue, grey, orange } from '@mui/material/colors';

export const inputFormat = 'DD/MM/YYYY';

export const statisticData = [
  {
    id: 1,
    icon: (
      <IconButton size='large' color='primary'>
        <BuildIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'Job',
    field: 'JobCount',
  },
  {
    id: 2,
    icon: (
      <IconButton size='large' color='secondary'>
        <AssignmentIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'Quote',
    field: 'QuoteCount',
  },
  {
    id: 3,
    icon: (
      <IconButton size='large' color='info'>
        <EventNoteIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'Visit',
    field: 'VisitCount',
  },
  {
    id: 4,
    icon: (
      <IconButton size='large' color='success'>
        <CreditCardIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'Invoice',
    field: 'InvoiceCount',
  },
  {
    id: 5,
    icon: (
      <IconButton size='large' color='error'>
        <DescriptionIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'PO',
    field: 'PurchaseOrderCount',
  },
  {
    id: 6,
    icon: (
      <IconButton size='large' color='warning'>
        <MicrowaveIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'Asset',
    field: 'AssetCount',
  },
  {
    id: 6.1,
    icon: (
      <IconButton size='large' color='default'>
        <EventAvailableIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'PPM Contract',
    field: 'PPMContractCount',
  },
  {
    id: 6.2,
    icon: (
      <IconButton size='large' color='success'>
        <EventIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'PPM Quote',
    field: 'PPMQuoteCount',
  },
  {
    id: 6.3,
    icon: (
      <IconButton size='large' color='warning'>
        <EventIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'PPM Invoice',
    field: 'PPMInvoiceCount',
  },
  {
    id: 8,
    icon: (
      <IconButton size='large' color='primary'>
        <FactoryIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'Supplier',
    field: 'SupplierCount',
  },
  {
    id: 9,
    icon: (
      <IconButton size='large' color='warning'>
        <PeopleIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'Engineer',
    field: 'EngineerCount',
  },
  {
    id: 10,
    icon: (
      <IconButton size='large' color='info'>
        <LayersIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'Part',
    field: 'PartCount',
  },
  {
    id: 11,
    icon: (
      <IconButton size='large' color='error'>
        <CreditCardIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'CGrouped Invoice',
    field: 'CGroupedInvoiceCount',
  },
  {
    id: 12,
    icon: (
      <IconButton size='large' color='default'>
        <LayersIcon sx={{ fontSize: 32 }} />
      </IconButton>
    ),
    count: '0',
    details: 'Equipment',
    field: 'EquipmentCount',
  },
];

export const RANGE_DATE = {
  THIS_WEEK: 'This week',
  LAST_WEEK: 'Last week',
  THIS_MONTH: 'This month',
  LAST_MONTH: 'Last month',
  THIS_YEAR: 'This year',
  LAST_YEAR: 'Last year',
};

export const BUNDLE = {
  Micro: 1,
  Bronze: 2,
  Silver: 3,
  Gold: 4,
  Platinum: 5,
  Topup: 7,
  EarlyAdoption: 8,
};

export const BUNDLE_OBJECT = {
  Micro: {
    Id: 1,
    Label: 'Bronze',
    ColumnName: 'Bronze',
  },
  Bronze: {
    Id: 2,
    Label: 'Silver',
    ColumnName: 'Silver',
  },
  Silver: {
    Id: 3,
    Label: 'Gold',
    ColumnName: 'Gold',
  },
  Gold: {
    Id: 4,
    Label: 'Platinum',
    ColumnName: 'Platinum',
  },
  Platinum: {
    Id: 5,
    Label: 'Diamond',
    ColumnName: 'Diamond',
  },
  Topup: {
    Id: 7,
    Label: 'Add On',
    ColumnName: 'AddOn',
  },
  EarlyAdoption: {
    Id: 8,
    Label: 'Early Adoption',
    ColumnName: 'EarlyAdoption',
  },
};

export const SUBSCRIPTION_TYPE = {
  None: 0,
  Stripe: 1,
  GoCardless: 2,
};

export const SUBSCRIPTION_TYPES = Object.keys(SUBSCRIPTION_TYPE).map(
  (item, idx) => ({
    Id: idx,
    Name: item,
  }),
);

export const SUBSCRIPTION_TYPE_COLOR = {
  None: grey[600],
  Stripe: blue[800],
  GoCardless: orange[800],
};

export const MULTI_TENANCY_STATUS = {
  None: 0,
  BackOffice: 1,
  CustomerPortal: 2,
};

const MULTI_TENANCY_STATUS_TITLE = {
  None: 'None',
  BackOffice: 'Back Office',
  CustomerPortal: 'Customer Portal',
};

export const MULTI_TENANCY_OPTIONS = Object.keys(MULTI_TENANCY_STATUS).map(
  (item, idx) => ({
    Id: idx,
    Name: MULTI_TENANCY_STATUS_TITLE[item],
    Code: item,
  }),
);
