import React from 'react';
import { Grid } from '@mui/material';
import { RoutePermittedRole } from 'shared/constants/AppConst';
import AppGridContainer from '@tenant/core/AppGridContainer';
import AppInfoView from '@tenant/core/AppInfoView';
import AppAnimate from '@tenant/core/AppAnimate';
import ReachExpiryStatistic from './components/ReachExpiryStatistic';
import ReachCapStatistic from './components/ReachCapStatistic';
import BundleUsagePerBundleType from './components/BundleUsagePerBundleType';
import TenantPurchasesStatistic from './components/TenantPurchasesStatistic';
import RenewalDashboard from './components/RenewalDashboard';
import CreditControls from 'pages/apps/Company/components/CreditControls';
import PointConsumption from './components/PointConsumption';
import LazyLoad from 'react-lazy-load';
import { AuthorizePermission } from '@tenant/utility/constants/authorizePermission';
import AllowedAccess from '@tenant/core/AppPermission/AllowedAccess';

const PointsDashboards = () => {
  return (
    <>
      <AppAnimate animation='transition.slideUpIn' delay={200}>
        <AppGridContainer>
          <Grid item xs={12} md={12}>
            <PointConsumption />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReachExpiryStatistic />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReachCapStatistic />
          </Grid>
          <Grid item xs={12} md={6}>
            <LazyLoad offset={100}>
              <TenantPurchasesStatistic />
            </LazyLoad>
          </Grid>
          <Grid item xs={12} md={6}>
            <LazyLoad offset={100}>
              <BundleUsagePerBundleType />
            </LazyLoad>
          </Grid>
        </AppGridContainer>
      </AppAnimate>

      <AppInfoView />
    </>
  );
};

export const pointsDashBoardConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/point-dashboards',
    element: <PointsDashboards />,
  },
];

export default pointsDashBoardConfigs;
