import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import AppCard from '@tenant/core/AppCard';
import Box from '@mui/material/Box';

import jwtAxios from '@tenant/services/auth/jwt-auth';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import {
  formatNumber,
  getRangeDateByKeyName,
} from '@tenant/utility/helper/Utils';
import { RANGE_DATE } from 'pages/tenantDetail/constants';
import IntlMessages from '@tenant/utility/IntlMessages';
import { BUNDLE_API } from 'pages/tenantDetail/constants/Api';
import TableChart from '@tenant/core/App/Chart/TableChart';
import { Button, ButtonGroup } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableViewIcon from '@mui/icons-material/TableView';
import BundleUsagePerBundleTypeChart from './BundleUsagePerBundleTypeChart';

const BundleUsagePerBundleType = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rangeDate, setRangeDate] = useState(RANGE_DATE.THIS_YEAR);
  const [isTableView, setIsTableView] = useState(true);

  const columns = useMemo(
    () => [
      {
        field: 'BundleName',
        headerName: 'Bundle',
        sortable: false,
        flex: 1,
        sortable: true,
        renderCell: (params) => <Typography> {params.value}</Typography>,
      },
      {
        field: 'TotalPointsPurchased',
        sortable: false,
        minWidth: 120,
        type: 'number',
        sortable: true,
        renderCell: (params) => (
          <Typography sx={{ color: 'success.main' }}>
            {formatNumber(params.value ?? 0)}
          </Typography>
        ),
        renderHeader: () => (
          <Typography variant='h6' component='h6'>
            <IntlMessages id='dashboard.bundle.totalPoints' />
          </Typography>
        ),
      },
      {
        field: 'TotalPurchases',
        // headerName: 'Total Purchases',
        sortable: false,
        minWidth: 120,
        type: 'number',
        sortable: true,
        renderCell: (params) => (
          <Typography sx={{ color: 'primary.main' }}>
            {formatNumber(params.value ?? 0)}
          </Typography>
        ),
        renderHeader: () => (
          <Typography variant='h6' component='h6'>
            <IntlMessages id='dashboard.bundle.totalPointsPurchased' />
          </Typography>
        ),
      },
      {
        field: 'TotalTenants',
        sortable: false,
        minWidth: 120,
        type: 'number',
        sortable: true,
        renderCell: (params) => (
          <Typography sx={{ color: 'primary.main' }}>
            {formatNumber(params.value ?? 0)}
          </Typography>
        ),
        renderHeader: () => (
          <Typography variant='h6' component='h6'>
            <IntlMessages id='dashboard.bundle.totalTenants' />
          </Typography>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    getList();
  }, []);

  const getList = useCallback(
    async (selectedKey) => {
      try {
        setIsLoading(true);

        const { start, end } = getRangeDateByKeyName(selectedKey || rangeDate);
        const response = await jwtAxios.get(BUNDLE_API.GET_BUNDLE_SALES, {
          params: {
            FromDate: start,
            ToDate: end,
          },
        });

        if (!Array.isArray(response?.data?.ObjectData)) {
          return;
        }

        setData(response?.data?.ObjectData);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [rangeDate],
  );

  const handleChangeDateRange = (data) => {
    const { keyName } = data || {};
    setRangeDate(keyName);
    getList(keyName);
  };

  const onChangeTab = (isTableView) => {
    setIsTableView(isTableView);
  };

  return (
    <AppCard
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2} display={'flex'}>
            <IntlMessages id='dashboard.bundleSales' />
            <Typography ml={1} variant='body2'>
              (Total Points Sold:{' '}
              {formatNumber(
                data?.reduce((a, b) => a + (b?.TotalPointsPurchased ?? 0), 0) ??
                  0,
              )}
              ){' '}
            </Typography>
          </Box>
        </Box>
      }
      sxStyle={{
        height: '100%',
      }}
      action={
        <Box display={'flex'}>
          <Box mr={2}>
            <DateMenu
              defaultValue={rangeDate}
              handleChangeValue={handleChangeDateRange}
            />
          </Box>
          <Box>
            <ButtonGroup variant='outlined' aria-label='Basic button group'>
              <Button
                variant={isTableView ? 'contained' : 'outlined'}
                onClick={() => onChangeTab(true)}
              >
                <TableViewIcon />
              </Button>
              <Button
                variant={!isTableView ? 'contained' : 'outlined'}
                onClick={() => onChangeTab(false)}
              >
                <BarChartIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      }
    >
      {isTableView && (
        <TableChart
          isLoading={isLoading}
          rows={data}
          columns={columns}
          uniqueProp={'BundleId'}
        />
      )}

      {!isTableView && (
        <Box height={325}>
          <BundleUsagePerBundleTypeChart data={data} loading={isLoading} />
        </Box>
      )}
    </AppCard>
  );
};

export default React.memo(BundleUsagePerBundleType);
