import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  List,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import IntlMessages from '@tenant/utility/IntlMessages';
import { handleDownload } from '@tenant/utility/Utils';
import AppLoader from '@tenant/core/AppLoader';
import { inputFormat } from 'pages/tenantDetail/constants';
import {
  CONTRACT_LENGTH_BY_PAYMENT_ENUM,
  PACKAGE_TYPE_ENUM_OBJECT,
  PAYMENT_METHODS_ENUM_OBJECT,
} from '../../contants/enums';
import useCompany from '../../hooks/useCompany';
import TenantsGroups from '../CompanyAddContent/TenantsGroups';
import LockPointsTable from './LockPointsTable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DetailInfoContent = ({
  contractId,
  lockPointHistory,
  onCallbackAfterUnlockPoint,
  contract,
  loading,
  tenants,
  onGetLockPointHistory,
  loadingLockPoint,
  isLoadedLockPoints,
  isAnnual,
}) => {
  const paymentTerms = useSelector(({ company }) => company.paymentTerms);

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const { downloadAttachment } = useCompany();

  const paymentTerm = paymentTerms.find(
    (e) => e.Id === contract?.PaymentTermsId,
  );

  const paidUser = useMemo(() => {
    const freeUser = contract?.NumberOfFreeUsers || 0;
    const maximumNumberOfUsers = contract?.MaximumNumberOfUsers || 0;
    if (maximumNumberOfUsers - freeUser < 0) {
      return 0;
    }
    return maximumNumberOfUsers - freeUser;
  }, [contract]);

  const onDownloadAttachment = () => {
    const payload = { id: contract?.AttachmentId };

    setIsLoadingDownload(true);
    downloadAttachment(payload, (data) => {
      handleDownload(data, contract?.AttachmentName);
      setIsLoadingDownload(false);
    });
  };

  const onShowLockPointTable = () => {
    if (isLoadedLockPoints) {
      return;
    }
    onGetLockPointHistory?.(contractId);
  };

  return (
    <>
      {isLoadingDownload && <AppLoader />}

      <Box
        className='content-wrapper'
        sx={{
          '& .MuiPaper-root': { boxShadow: 'none' },
          '& .MuiAccordionSummary-root': {
            backgroundColor: '#f7f7f7',
            pl: 4,
            pr: 4,
            border: '1px solid #ddd',
            fontWeight: 600,
          },
          '& .MuiAccordion-root::before': {
            opacity: 0,
          },
        }}
      >
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            Contract Detail
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: 0 }}
            >
              <List
                aria-label='secondary contract-layer-information'
                sx={{
                  paddingTop: 0,
                }}
              >
                {loading ? (
                  Array.from(new Array(3)).map((_, index) => (
                    <Skeleton
                      variant='rectangular'
                      height={150}
                      key={index}
                      sx={{ mb: 4 }}
                    />
                  ))
                ) : (
                  <Stack
                    direction='column'
                    spacing={6}
                    divider={<Divider orientation='horizontal' flexItem />}
                  >
                    <Grid container spacing={2} sx={{ ml: 0, pt: 4 }}>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.contactName' />
                          }
                          secondary={
                            contract?.Contacts?.[0]?.ContactName ?? '-'
                          }
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <Tooltip
                          title={contract?.EmailAddress}
                          placement='bottom-start'
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: { offset: [0, -8] },
                                },
                              ],
                            },
                          }}
                        >
                          <ListItemText
                            primary={
                              <IntlMessages id='contractLayer.contactEmailAddress' />
                            }
                            secondary={contract?.EmailAddress ?? '-'}
                            secondaryTypographyProps={{
                              sx: {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              },
                            }}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={<IntlMessages id='contractLayer.Country' />}
                          secondary={contract?.Country ?? '-'}
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={<IntlMessages id='contractLayer.VAT' />}
                          secondary={contract?.VAT + '%' ?? '-'}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.contractLength' />
                          }
                          secondary={
                            contract?.ContractLength
                              ? CONTRACT_LENGTH_BY_PAYMENT_ENUM[
                                  contract?.ContractLength
                                ]
                              : '-'
                          }
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.packageType' />
                          }
                          secondary={
                            contract?.PackageType
                              ? PACKAGE_TYPE_ENUM_OBJECT[contract?.PackageType]
                              : '-'
                          }
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.paymentMethod' />
                          }
                          secondary={
                            contract?.PaymentMethod?.PaymentMethodType >= 0
                              ? PAYMENT_METHODS_ENUM_OBJECT[
                                  contract?.PaymentMethod?.PaymentMethodType
                                ]
                              : '-'
                          }
                        />
                      </Grid>

                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.paymentTerms' />
                          }
                          secondary={paymentTerm?.Description ?? '-'}
                        />
                      </Grid>

                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.membership' />
                          }
                          secondary={contract?.Membership ?? '-'}
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.promoCode' />
                          }
                          secondary={
                            contract?.PromoCode
                              ? contract?.PromoCode === 1
                                ? contract?.PromoCode + ' month'
                                : contract?.PromoCode + ' months'
                              : '-'
                          }
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={<IntlMessages id='contractLayer.stripeID' />}
                          secondary={
                            contract?.PaymentMethod?.PaymentSystemId ?? '-'
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.subscriptionStart' />
                          }
                          secondary={
                            contract?.StartDate
                              ? moment(contract?.StartDate).format(inputFormat)
                              : '-'
                          }
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.subscriptionEnd' />
                          }
                          secondary={
                            contract?.EndDate
                              ? moment(contract?.EndDate).format(inputFormat)
                              : '-'
                          }
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.contractSignDate' />
                          }
                          secondary={
                            contract?.SignedDate
                              ? moment(contract?.SignedDate).format(inputFormat)
                              : '-'
                          }
                        />
                      </Grid>
                      {contract?.CanceledAt ? (
                        <Grid item xs={3} justifyContent='center'>
                          <ListItemText
                            primary={
                              <Typography color={'#e53935'}>
                                <IntlMessages id='contractLayer.canceledDate' />
                              </Typography>
                            }
                            secondary={
                              contract?.CanceledAt
                                ? moment(contract?.CanceledAt).format(
                                    inputFormat,
                                  )
                                : '-'
                            }
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={3} justifyContent='center'>
                          <ListItemText
                            primary={
                              <IntlMessages id='contractLayer.renewalDate' />
                            }
                            secondary={
                              contract?.RenewDate
                                ? moment(contract?.RenewDate).format(
                                    inputFormat,
                                  )
                                : '-'
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.maximumNumberOfUsers' />
                          }
                          secondary={contract?.MaximumNumberOfUsers ?? '-'}
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={<IntlMessages id='contractLayer.freeUser' />}
                          secondary={contract?.NumberOfFreeUsers ?? '-'}
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={<IntlMessages id='contractLayer.paidUser' />}
                          secondary={paidUser}
                        />
                      </Grid>
                      {tenants?.Tenants?.length === 1 && (
                        <Grid item xs={3} justifyContent='center'>
                          <ListItemText
                            primary={
                              <IntlMessages id='contractLayer.multiTenancyStatus' />
                            }
                            secondary={
                              tenants?.Tenants[0].Tenant
                                ?.MultiTenancyBackOfficeStatus
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={<IntlMessages id='productForm.balance' />}
                          secondary={contract?.PointsBalance ?? '-'}
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={<IntlMessages id='lockedPoints' />}
                          secondary={contract?.LockedPoints ?? '-'}
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.subcontractorLicenses' />
                          }
                          secondary={
                            contract?.SubcontractorConnectionCount ?? '-'
                          }
                        />
                      </Grid>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={
                            <IntlMessages id='contractLayer.vehicleTracking' />
                          }
                          secondary={
                            contract?.VehicleTrackingDeviceCount ?? '-'
                          }
                        />
                      </Grid>
                      {tenants?.Tenants?.length === 1 && (
                        <Grid item xs={3} justifyContent='center'>
                          <ListItemText
                            primary={
                              <IntlMessages id='contractLayer.customerPortalTenancyStatus' />
                            }
                            secondary={
                              tenants?.Tenants[0].Tenant
                                ?.MultiTenancyPortalStatus
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} justifyContent='center'>
                        <ListItemText
                          primary={<IntlMessages id='company.pandaDoc' />}
                          secondary={
                            contract?.AttachmentId ? (
                              <Typography
                                component='span'
                                sx={{
                                  color: '#0000ee',
                                  cursor: 'pointer',
                                  fontSize: '12px',
                                }}
                                onClick={onDownloadAttachment}
                              >
                                {contract?.AttachmentName}
                              </Typography>
                            ) : (
                              '-'
                            )
                          }
                        />
                      </Grid>
                      {contract?.CancelReason && (
                        <Grid item xs={3} justifyContent='center'>
                          <ListItemText
                            primary={
                              <IntlMessages id='contractLayer.cancelReason' />
                            }
                            secondary={contract?.CancelReason ?? '-'}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {tenants?.Tenants?.length > 1 && (
                      <Grid container spacing={2}>
                        <TenantsGroups formValue={tenants} isCreate={false} />
                      </Grid>
                    )}
                  </Stack>
                )}

                {!tenants?.Tenants && (
                  <Skeleton variant='rectangular' height={150} sx={{ mb: 4 }} />
                )}
              </List>
            </Box>
          </AccordionDetails>
        </Accordion>

        {isAnnual && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              onClick={onShowLockPointTable}
              sx={{ pointerEvents: loadingLockPoint && 'none' }}
            >
              Lock Points History
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ height: 450 }} p={1} mt={4}>
                <LockPointsTable
                  loading={loadingLockPoint}
                  contractId={contractId}
                  PointsBalance={contract?.PointsBalance}
                  lockPointHistory={lockPointHistory}
                  onCallbackAfterUnlockPoint={onCallbackAfterUnlockPoint}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </>
  );
};

export default DetailInfoContent;

DetailInfoContent.propTypes = {
  contractId: PropTypes.string,
  contract: PropTypes.object,
  lockPointHistory: PropTypes.array,
  onCallbackAfterUnlockPoint: PropTypes.func,
  onGetLockPointHistory: PropTypes.func,
  loading: PropTypes.bool,
  loadingLockPoint: PropTypes.bool,
  isLoadedLockPoints: PropTypes.bool,
  isAnnual: PropTypes.bool,
  tenants: PropTypes.object,
};
