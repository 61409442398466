import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import IntlMessages from '@tenant/utility/IntlMessages';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  debounce,
} from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, createStyles } from '@mui/styles';
import { API } from '../constants';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import AppCard from '@tenant/core/AppCard';
import PercentMenu from '@tenant/core/App/Menu/Percent/PercentMenu';
import TableChart from '@tenant/core/App/Chart/TableChart';
import { ORDER_DIRECTION } from '@tenant/utility/constants/enum';
import { formatNumber } from '@tenant/utility/helper/Utils';
import AppTableSortLabel from '@tenant/core/App/Table/AppTableSortLabel';

const rowsPerPage = 50;
const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} component={Paper} />
  )),
  Table: (props) => (
    <Table {...props} stickyHeader sx={{ tableLayout: 'fixed' }} />
  ),
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
      left: 2,
    },
  }),
);

const columns = [
  {
    field: 'CompanyName',
    flex: 1,
    minWidth: 230,
    headerName: 'Company',
    sortable: false,
  },
  {
    field: 'PointsBurnt',
    minWidth: 130,
    headerName: 'Used Points',
    sortable: false,
  },
  {
    field: 'RemainingPoint',
    minWidth: 130,
    headerName: 'Remaining Points',
    sortable: false,
  },
  {
    field: 'UsagePercent',
    minWidth: 170,
    headerName: 'Usage Percentage (%)',
    sortable: false,
  },
];

const ReachCapStatistic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [percent, setPercent] = useState(90);
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState({});
  const [keyRenderTable, setKeyRenderTable] = useState(0);

  useEffect(() => {
    getList();
  }, []);

  const getList = useCallback(
    async ({ percentValue, field, direction, pageIndex = 1 } = {}) => {
      try {
        setIsLoading(true);
        const response = await jwtAxios.get(API.GET_REACH_CAP_STATISTIC, {
          params: {
            Percent: percentValue ?? percent,
            PageIndex: pageIndex,
            PageSize: rowsPerPage,
            field: field ? field : undefined,
            direction: direction ? ORDER_DIRECTION[direction] : undefined,
          },
        });

        if (response?.status === 200 && response?.data?.Success) {
          if (pageIndex === 1) {
            setData(response?.data?.ObjectData?.Items || []);
          } else {
            setData((s) => [
              ...s,
              ...(response?.data?.ObjectData?.Items || []),
            ]);
          }

          setTotal(response?.data?.ObjectData?.Total);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [percent],
  );

  const getListDebounce = useCallback(
    debounce((percentValue, field, direction, pageIndex = 1) => {
      getList(percentValue, field, direction, (pageIndex = 1));
    }, 500),
    [getList],
  );

  const handleChangePercent = (data) => {
    setPage(1);
    setPercent(data.Percent);
    getList({
      percentValue: data.Percent,
      field: orderBy.field,
      direction: orderBy.direction,
    });
  };

  const handleReachedEnd = () => {
    if (data.length >= total) return;

    getList({
      pageIndex: page + 1,
      field: orderBy.field,
      direction: orderBy.direction,
    });
    setPage((prevPage) => prevPage + 1);
    setKeyRenderTable((s) => s + 1);
  };

  const handleSortColumn = (column = {}) => {
    const { field, direction } = column;
    const currentDirection = !direction
      ? 'asc'
      : direction === 'asc'
      ? 'desc'
      : 'asc';
    const currentField = !currentDirection ? '' : field;
    setOrderBy({
      field: currentField,
      direction: currentDirection,
    });
    setPage(1);
    setKeyRenderTable((s) => s + 1);
    getListDebounce({
      percentValue: percent,
      field: currentField,
      direction: currentDirection,
    });
  };

  return (
    <AppCard
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2}>
            <IntlMessages id='dashboard.reachCapTitle' />
            <Typography ml={2} variant='caption'>
              ( Total: {total ?? 0} )
            </Typography>{' '}
          </Box>
        </Box>
      }
      action={
        <PercentMenu
          beforeText='≥'
          defaultValue={percent}
          handleChangeValue={handleChangePercent}
        />
      }
      sxStyle={{
        position: 'relative',
      }}
    >
      {isLoading && (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      )}

      {!!data.length ? (
        <TableVirtuoso
          style={{ height: 325 }}
          data={data}
          components={TableComponents}
          endReached={handleReachedEnd}
          fixedHeaderContent={() => (
            <TableRow>
              <TableCell
                sx={{
                  width: 125,
                  backgroundColor: '#FFFFFF',
                }}
              >
                <AppTableSortLabel
                  orderByField={orderBy.field ?? ''}
                  orderByDirection={orderBy.direction ?? ''}
                  columnName={'CompanyName'}
                  columnLabel={'Company'}
                  handleSortColumn={handleSortColumn}
                />
              </TableCell>

              <TableCell
                align='right'
                sx={{ backgroundColor: '#FFFFFF', width: 175 }}
              >
                <AppTableSortLabel
                  orderByField={orderBy.field ?? ''}
                  orderByDirection={orderBy.direction ?? ''}
                  columnName={'PointsBurnt'}
                  columnLabel={'Used Points'}
                  handleSortColumn={handleSortColumn}
                />
              </TableCell>
              <TableCell
                align='right'
                sx={{
                  width: 170,
                  backgroundColor: '#FFFFFF',
                }}
              >
                <AppTableSortLabel
                  orderByField={orderBy.field ?? ''}
                  orderByDirection={orderBy.direction ?? ''}
                  columnName={'RemainingPoint'}
                  columnLabel={'Remaining Points'}
                  handleSortColumn={handleSortColumn}
                />
              </TableCell>
              <TableCell
                align='right'
                sx={{ backgroundColor: '#FFFFFF', width: 200 }}
              >
                <AppTableSortLabel
                  orderByField={orderBy.field ?? ''}
                  orderByDirection={orderBy.direction ?? ''}
                  columnName={'UsagePercent'}
                  columnLabel={'Usage Percentage (%)'}
                  handleSortColumn={handleSortColumn}
                />
              </TableCell>
            </TableRow>
          )}
          itemContent={(idx, item) => (
            <React.Fragment key={idx}>
              <>
                <TableCell sx={{ minWidth: 260, maxWidth: 260 }}>
                  {item?.CompanyId ? (
                    <NavLink
                      className='MuiDataGrid-cellContent'
                      to={`/company/${item.CompanyId}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Tooltip title={item?.CompanyName}>
                        <Typography
                          variant='body2'
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item?.CompanyName}
                        </Typography>
                      </Tooltip>
                    </NavLink>
                  ) : (
                    item?.CompanyName
                  )}
                </TableCell>
                <TableCell
                  align='right'
                  sx={{ color: 'primary.main', fontWeight: 500 }}
                >
                  {formatNumber(item?.PointsBurnt ?? 0)}
                </TableCell>
                <TableCell
                  align='right'
                  sx={{ color: 'primary.main', fontWeight: 500 }}
                >
                  {formatNumber(item?.RemainingPoint ?? 0)}
                </TableCell>
                <TableCell
                  align='right'
                  sx={{ color: 'error.main', fontWeight: 500 }}
                >
                  {item?.UsagePercent}
                </TableCell>
              </>
            </React.Fragment>
          )}
        />
      ) : (
        <TableChart rows={[]} columns={columns} uniqueProp={''} />
      )}
    </AppCard>
  );
};

export default React.memo(ReachCapStatistic);
