import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppGridContainer from '@tenant/core/AppGridContainer';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import { numberToCurrency } from '@tenant/utility/helper/Utils';
import XeroInvoiceTable from './XeroInvoiceTable';

const StepAddInvoice = ({ detail, onChangeDiscount }) => {
  const durationText = useMemo(() => {
    const durations = [];

    if (detail?.MonthsRemaining > 0) {
      durations.push(`${detail.MonthsRemaining} month(s)`);
    }

    if (
      detail?.DaysRemaining > 0 ||
      (!detail?.DaysRemaining && !detail.MonthsRemaining)
    ) {
      durations.push(`${detail.DaysRemaining || 0} days(s)`);
    }

    return durations.join(' ');
  }, [detail?.MonthsRemaining, detail.DaysRemaining]);

  return (
    <DialogContent>
      <Box mb={2} m={-4}>
        <AppGridContainer spacing={5}>
          <Grid item xs={12} lg={6}>
            <Box fontWeight={550} component='span' mr={2}>
              Access License Fee:
            </Box>
            {numberToCurrency(detail?.Total ?? 0, 2, 'GBP')}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box fontWeight={550} component='span' mr={2}>
              Duration:
            </Box>
            {durationText}
          </Grid>
          <Grid item xs={12} lg={12}>
            <XeroInvoiceTable
              invoice={detail}
              onChangeDiscount={onChangeDiscount}
              durationText={durationText}
            />
          </Grid>
        </AppGridContainer>
      </Box>
    </DialogContent>
  );
};

export default React.memo(StepAddInvoice);
StepAddInvoice.propTypes = {
  detail: PropTypes.object,
  onChangeDiscount: PropTypes.func,
};
