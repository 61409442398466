import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Grid,
  Box,
  Typography,
  styled,
  ListItemText,
  ListItem,
  Tooltip,
  Link,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Brightness1Icon from '@mui/icons-material/Brightness1';

import { numberToCurrency } from '@tenant/utility/helper/Utils';
import IntlMessages from '@tenant/utility/IntlMessages';
import TableChart from '@tenant/core/App/Chart/TableChart';
import { INVOICE_TYPE_VALUE, INVOICE_STATUS } from '../../contants/enums';
import AuditInvoiceDetail from './AuditInvoiceDetail';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';

const StyledStack = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.grey[100],
  marginTop: theme.spacing(4),
}));

const AuditInvoices = ({ data = [], loading = false }) => {
  const [detailData, setDetailData] = useState(null);

  const summary = useMemo(() => {
    const totalTaxAmount = data?.reduce(
      (total, inv) => (inv.InvoiceType !== 4 ? total + inv.TotalTax : total),
      0,
    );
    const totalDiscount = data?.reduce(
      (total, inv) =>
        inv.InvoiceType !== 4 ? total + inv.TotalDiscount : total,
      0,
    );
    const totalPayment = data?.reduce(
      (total, inv) => (inv.InvoiceType !== 4 ? total + inv.TotalAmount : total),
      0,
    );

    return {
      totalTaxAmount,
      totalDiscount,
      totalPayment,
    };
  }, [data]);

  const columns = useMemo(
    () => [
      {
        field: 'InvoiceNumber',
        headerName: 'Invoice No',
        minWidth: 200,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <Tooltip title='View Invoice' placement='right'>
            <Link href={params.row.XeroUrl}>{params.value}</Link>
          </Tooltip>
        ),
      },
      {
        field: 'InvoiceType',
        headerName: 'Invoice Type',
        sortable: false,
        flex: 1,
        minWidth: 150,
        renderCell: (params) => <>{INVOICE_TYPE_VALUE[params.value]}</>,
      },
      {
        field: 'SubscriptionStartDate',
        headerName: 'Start Date',
        sortable: false,
        minWidth: 125,
        renderCell: (params) =>
          params.value ? formatDateFromISO(params.value) : '',
      },
      {
        field: 'SubscriptionEndDate',
        headerName: 'End Date',
        sortable: false,
        minWidth: 125,
        renderCell: (params) =>
          params.value ? formatDateFromISO(params.value) : '',
      },
      {
        field: 'Status',
        headerName: 'Status',
        renderCell: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Brightness1Icon
                sx={{
                  color: INVOICE_STATUS[params.value]?.Color || '',
                  fontSize: 14,
                  marginRight: 1,
                }}
              />
              {INVOICE_STATUS[params.value]?.Name || '-'}
            </span>
          );
        },
      },
      {
        field: 'TotalDiscount',
        headerName: 'Discount',
        sortable: false,
        flex: 1,
        type: 'number',
        renderCell: (params) => (
          <>{numberToCurrency(params.value ?? 0, 2, 'GBP')}</>
        ),
      },
      {
        field: 'TotalTax',
        headerName: 'Tax Amount',
        sortable: false,
        flex: 1,
        type: 'number',
        renderCell: (params) => (
          <>{numberToCurrency(params.value ?? 0, 2, 'GBP')}</>
        ),
      },
      {
        field: 'TotalAmount',
        headerName: 'Amount',
        sortable: false,
        flex: 1,
        type: 'number',
        renderCell: (params) => (
          <>{numberToCurrency(params.value ?? 0, 2, 'GBP')}</>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          return [
            <>
              {!!params.row.InvoiceLines.length ? (
                <Tooltip title={'Detail'}>
                  <GridActionsCellItem
                    icon={<VisibilityIcon fontSize='small' />}
                    label='Detail'
                    onClick={() => handleViewDetail?.(params.row.InvoiceId)}
                  />
                </Tooltip>
              ) : null}
            </>,
          ];
        },
      },
    ],
    [data],
  );

  const handleViewDetail = useCallback(
    (invoiceId) => {
      const lines = (data ?? []).find((d) => d.InvoiceId === invoiceId);

      const detailData = {
        ...lines,
        InvoiceLines: (lines?.InvoiceLines ?? []).map((inv, idx) => ({
          ...inv,
          index: idx,
        })),
      };

      setDetailData(detailData);
    },
    [data],
  );

  return (
    <Box sx={{ height: 450 }}>
      {!detailData && (
        <Grid item container xs={12} justifyContent={'flex-end'}>
          <Grid item xs={12}>
            <TableChart
              wrapperStyle={{ height: 350 }}
              isLoading={loading}
              columns={columns}
              rows={data ?? []}
              uniqueProp={'InvoiceId'}
            />
          </Grid>

          <Grid item xs={4} sm={3}>
            <StyledStack direction='column'>
              <ListItem
                secondaryAction={
                  <Typography
                    sx={{
                      color: 'primary.main',
                    }}
                  >
                    {numberToCurrency(summary?.totalDiscount ?? 0, 2, 'GBP')}
                  </Typography>
                }
              >
                <ListItemText
                  primary={<IntlMessages id='common.totalDiscount' />}
                />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Typography
                    sx={{
                      color: 'primary.main',
                    }}
                  >
                    {numberToCurrency(summary?.totalTaxAmount ?? 0, 2, 'GBP')}
                  </Typography>
                }
              >
                <ListItemText
                  primary={<IntlMessages id='common.totalTaxAmount' />}
                />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 600,
                    }}
                  >
                    {numberToCurrency(summary?.totalPayment ?? 0, 2, 'GBP')}
                  </Typography>
                }
              >
                <ListItemText
                  primary={<IntlMessages id='bundle.total' />}
                  primaryTypographyProps={{
                    fontWeight: 500,
                  }}
                />
              </ListItem>
            </StyledStack>
          </Grid>
        </Grid>
      )}

      {!!detailData && (
        <AuditInvoiceDetail
          data={detailData}
          handleGoBack={() => {
            setDetailData(null);
          }}
        />
      )}
    </Box>
  );
};

export default React.memo(AuditInvoices);

AuditInvoices.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};
