import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Tooltip,
  Stack,
  styled,
  Grid,
  ListItemText,
  ListItem,
  Divider,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IntlMessages from '@tenant/utility/IntlMessages';
import TableChart from '@tenant/core/App/Chart/TableChart';
import AppTooltip from '@tenant/core/AppTooltip';
import { numberToCurrency } from '@tenant/utility/helper/Utils';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';

const columns = [
  {
    field: 'Item',
    headerName: 'Item',
    sortable: false,
    flex: 1,
    width: 175,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <Typography noWrap variant='body2'>
          {params.value}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: 'Description',
    headerName: 'Description',
    sortable: false,
    width: 175,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <Typography noWrap variant='body2'>
          {params.value}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: 'SubscriptionStartDate',
    headerName: 'Start Date',
    sortable: false,
    renderCell: (params) => (
      <Typography variant='body2'>{formatDateFromISO(params.value)}</Typography>
    ),
  },
  {
    field: 'SubscriptionEndDate',
    headerName: 'End Date',
    sortable: false,
    renderCell: (params) => (
      <Typography variant='body2'>{formatDateFromISO(params.value)}</Typography>
    ),
  },
  {
    field: 'UnitAmount',
    headerName: 'Unit Amount',
    sortable: false,
    type: 'number',
    renderCell: (params) => (
      <Typography variant='body2'>{params.value}</Typography>
    ),
  },
  {
    field: 'Quantity',
    headerName: 'Quantity',
    sortable: false,
    type: 'number',
    renderCell: (params) => (
      <Typography variant='body2'>{params.value}</Typography>
    ),
  },
  {
    field: 'DiscountValue',
    headerName: 'Discount Value',
    sortable: false,
    width: 100,
    type: 'number',
    renderCell: (params) => (
      <Typography variant='body2' color='success.main'>
        {numberToCurrency(params.value ?? 0, 2, 'GBP')}
      </Typography>
    ),
  },
  {
    field: 'TaxRateValue',
    headerName: 'Tax Rate Value',
    sortable: false,
    type: 'number',
    renderCell: (params) => (
      <Typography variant='body2' color='error.main'>
        {params.value + '%'}
      </Typography>
    ),
  },
  {
    field: 'TaxAmount',
    headerName: 'Tax Amount',
    sortable: false,
    type: 'number',
    renderCell: (params) => (
      <Typography variant='body2' color='error.main'>
        {numberToCurrency(params.value ?? 0, 2, 'GBP')}
      </Typography>
    ),
  },
  {
    field: 'Amount',
    headerName: 'Amount',
    sortable: false,
    type: 'number',
    renderCell: (params) => (
      <Typography variant='body2' color='info.main'>
        {numberToCurrency(params.value ?? 0, 2, 'GBP')}
      </Typography>
    ),
  },
];

const StyledStack = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.grey[100],
  marginTop: theme.spacing(4),
}));

const AuditInvoiceDetail = ({ data, handleGoBack }) => {
  return (
    <Box mt={2} sx={{ height: 450 }}>
      <Grid item container xs={12} justifyContent={'flex-end'}>
        <Grid item xs={12}>
          <Box display={'flex'}>
            <Box
              sx={{
                cursor: 'pointer',
              }}
              component='span'
              mr={{ xs: 2, sm: 4 }}
              onClick={handleGoBack}
            >
              <AppTooltip title={<IntlMessages id='common.back' />}>
                <ArrowBackIcon
                  sx={{
                    color: 'text.secondary',
                  }}
                />
              </AppTooltip>
            </Box>
            <Box mb={5}>
              <Typography
                variant='subtitle1'
                component='p'
                sx={{ fontWeight: 500 }}
              >
                <IntlMessages id='contract.invoiceDetails' />
              </Typography>
            </Box>
          </Box>

          <TableChart
            wrapperStyle={{ height: 250 }}
            columns={columns}
            rows={
              data?.InvoiceLines?.map((e) => ({
                ...e,
                SubscriptionStartDate: data?.SubscriptionStartDate,
                SubscriptionEndDate: data?.SubscriptionEndDate,
              })) ?? []
            }
            uniqueProp={'index'}
          />
        </Grid>

        <Grid item xs={4} sm={3}>
          <StyledStack direction='column'>
            <ListItem
              secondaryAction={
                <Typography
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  {numberToCurrency(data?.SubTotal ?? 0, 2, 'GBP')}
                </Typography>
              }
            >
              <ListItemText primary={<IntlMessages id='bundle.subTotal' />} />
            </ListItem>

            <ListItem
              secondaryAction={
                <Typography
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  {numberToCurrency(data?.TotalDiscount ?? 0, 2, 'GBP')}
                </Typography>
              }
            >
              <ListItemText
                primary={<IntlMessages id='common.includesDiscount' />}
              />
            </ListItem>
            <ListItem
              secondaryAction={
                <Typography
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  {numberToCurrency(data?.TotalTax ?? 0, 2, 'GBP')}
                </Typography>
              }
            >
              <ListItemText
                primary={<IntlMessages id='common.totalTaxAmount' />}
              />
            </ListItem>

            <Divider variant='middle' />

            <ListItem
              secondaryAction={
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 600,
                  }}
                >
                  {numberToCurrency(data?.TotalAmount ?? 0, 2, 'GBP')}
                </Typography>
              }
            >
              <ListItemText
                primary={<IntlMessages id='bundle.total' />}
                primaryTypographyProps={{
                  fontWeight: 500,
                }}
              />
            </ListItem>
          </StyledStack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(AuditInvoiceDetail);

AuditInvoiceDetail.propTypes = {
  data: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func,
};
