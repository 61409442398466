import React, { useEffect, useMemo, useState } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import { formatDate } from '@tenant/utility/helper/DateHelper';
import { STATISTIC_DATE_GROUP_TYPE } from '@tenant/utility/constants/enum';
import {
  formatNumber,
  getFontSizeLabelChart,
  getKeyByValue,
} from '@tenant/utility/helper/Utils';

const UserUsageChart = ({ height, data, dateGroupType }) => {
  const [chartData, setChartData] = useState([]);
  const isDayType = useMemo(
    () => getKeyByValue(STATISTIC_DATE_GROUP_TYPE, dateGroupType) === 'Day',
    [dateGroupType],
  );

  useEffect(() => {
    setChartData(
      data.map((e) => ({
        ...e,
        Key: isDayType
          ? formatDate(e.Key, 'DD MMM')
          : formatDate(e.Key, 'MMM YYYY'),
        BackOfficeOther: e.BackOfficeCount - e.BackOfficeIncrease,
        EngineerOther: e.EngineerCount - e.EngineerIncrease,
      })),
    );
  }, [data]);

  return (
    <ResponsiveContainer width='100%' height={height ?? 350}>
      <BarChart
        width={500}
        height={300}
        data={chartData}
        margin={{ top: 50, right: 0, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey='Key'
          fontSize={getFontSizeLabelChart(data?.length ?? 0)}
        />
        <YAxis
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              notation: 'compact',
              compactDisplay: 'short',
            }).format(value)
          }
        />
        <Tooltip
          formatter={(value) => formatNumber(value)}
          cursor={{ fill: '#f4f6f8' }}
        />
        <Legend verticalAlign='top' height={35} />
        <Bar dataKey='BackOfficeCount' stackId='1' name='BO' fill='#3498DB' />
        <Bar
          dataKey='BackOfficeIncrease'
          stackId='1'
          name='BO Created'
          fill='#FFBB28'
        />
        <Bar dataKey='EngineerCount' name='Mobile' stackId='2' fill='#82ca9d' />
        <Bar
          dataKey='EngineerIncrease'
          name='Mobile Created'
          stackId='2'
          fill='#FF8042'
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

UserUsageChart.propTypes = {
  data: PropTypes.array.isRequired,
  dateGroupType: PropTypes.number,
  height: PropTypes.number,
};

export default React.memo(UserUsageChart);
