import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import './loader.css';

const AppLoader = ({ isPortal, customClass }) => {
  return isPortal ? (
    createPortal(
      <div className={'app-loader ' + customClass}>
        <div className='loader-spin'>
          <span className='tenant-dot tenant-dot-spin'>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </span>
        </div>
      </div>,
      document.body,
    )
  ) : (
    <div className={'app-loader ' + customClass}>
      <div className='loader-spin'>
        <span className='tenant-dot tenant-dot-spin'>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </div>
  );
};

AppLoader.propTypes = {
  isPortal: PropTypes.bool,
  customClass: PropTypes.string,
};

export default AppLoader;
