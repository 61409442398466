import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import useScreenSize from '@tenant/utility/hooks/useScreenSize';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const AppGlossary = ({ title, iconProps, ...rest }) => {
  const [open, setOpen] = useState(false);
  const { isTooltipClick } = useScreenSize();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  if (!isTooltipClick) {
    return (
      <Tooltip title={title} {...rest} onClick={(e) => e.stopPropagation()}>
        <HelpOutlineIcon sx={{ fontSize: 18, ml: 2 }} {...iconProps} />
      </Tooltip>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title}
        {...rest}
      >
        <HelpOutlineIcon
          sx={{ fontSize: 18, ml: 2 }}
          {...iconProps}
          onClick={handleTooltipOpen}
        />
      </Tooltip>
    </ClickAwayListener>
  );
};

export default React.memo(AppGlossary);

AppGlossary.propTypes = {
  title: PropTypes.node.isRequired,
};
