import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  debounce,
} from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, createStyles } from '@mui/styles';

import { API } from '../constants';
import AppCard from '@tenant/core/AppCard';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import {
  formatNumber,
  getRangeDateByKeyName,
} from '@tenant/utility/helper/Utils';
import { RANGE_DATE } from 'pages/tenantDetail/constants';
import TableChart from '@tenant/core/App/Chart/TableChart';
import { visuallyHidden } from '@mui/utils';
import { ORDER_DIRECTION } from '@tenant/utility/constants/enum';
import {
  formatDate,
  formatDateFromISO,
} from '@tenant/utility/helper/DateHelper';
import AppTableSortLabel from '@tenant/core/App/Table/AppTableSortLabel';

const rowsPerPage = 50;
const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} component={Paper} />
  )),
  Table: (props) => (
    <Table {...props} stickyHeader sx={{ tableLayout: 'fixed' }} />
  ),
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const columns = [
  {
    field: 'CompanyName',
    flex: 1,
    minWidth: 250,
    headerName: 'Company',
    sortable: false,
  },
  {
    field: 'SubscriptionStart',
    minWidth: 180,
    headerName: 'Subscription Start',
    sortable: false,
  },
  {
    field: 'SubscriptionEnd',
    minWidth: 130,
    headerName: 'Subscription End',
    sortable: false,
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
      left: 2,
    },
  }),
);

const ReachExpiryStatistic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rangeDate, setRangeDate] = useState(RANGE_DATE.THIS_YEAR);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [orderBy, setOrderBy] = useState({});
  const [dateRangeSelect, setDateRangeSelect] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    getList();
  }, []);

  const getList = useCallback(
    async ({ keyName, field, direction, pageIndex = 1 } = {}) => {
      try {
        setIsLoading(true);

        const { start, end } = getRangeDateByKeyName(keyName || rangeDate);
        setDateRangeSelect([start, end]);
        const response = await jwtAxios.get(API.GET_REACH_EXPIRY_STATISTIC, {
          params: {
            FromDate: start,
            ToDate: end,
            PageIndex: pageIndex,
            PageSize: rowsPerPage,
            field: field || undefined,
            direction: direction ? ORDER_DIRECTION[direction] : undefined,
          },
        });

        if (response?.status === 200 && response?.data?.Success) {
          if (pageIndex === 1) {
            setData(response?.data?.ObjectData?.Items || []);
          } else {
            setData((s) => [
              ...s,
              ...(response?.data?.ObjectData?.Items || []),
            ]);
          }
          setTotal(response?.data?.ObjectData?.Total);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [rangeDate],
  );

  const getListDebounce = useCallback(
    debounce(({ keyName, field, direction, pageIndex = 1 } = {}) => {
      getList({ keyName, field, direction, pageIndex });
    }, 500),
    [getList],
  );

  const handleChangeDateRange = (data) => {
    const { keyName, startDate, endDate } = data || {};
    setDateRangeSelect([startDate, endDate]);
    setRangeDate(keyName);
    setPage(1);
    getList({ keyName, field: orderBy.field, direction: orderBy.direction });
  };

  const handleReachedEnd = () => {
    if (data.length >= total) return;

    getList({
      pageIndex: page + 1,
      field: orderBy.field,
      direction: orderBy.direction,
    });
    setPage((prevPage) => prevPage + 1);
  };

  const handleSortColumn = (column = {}) => {
    const { field, direction } = column;
    const currentDirection = !direction
      ? 'asc'
      : direction === 'asc'
      ? 'desc'
      : 'asc';
    const currentField = !currentDirection ? '' : field;
    setOrderBy({
      field: currentField,
      direction: currentDirection,
    });
    setPage(1);
    getListDebounce({
      start: dateRangeSelect[0],
      end: dateRangeSelect[1],
      field: currentField,
      direction: currentDirection,
    });
  };

  return (
    <AppCard
      title={
        <Box display='flex' alignItems='center'>
          <Box mr={2}>
            Companies Reaching Expiry{' '}
            <Typography variant='caption'>( Total: {total} )</Typography>{' '}
          </Box>
        </Box>
      }
      action={
        <DateMenu
          defaultValue={rangeDate}
          handleChangeValue={handleChangeDateRange}
        />
      }
      sxStyle={{
        position: 'relative',
      }}
    >
      {isLoading && (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      )}

      {!!data.length ? (
        <TableVirtuoso
          style={{ height: 325 }}
          data={data}
          components={TableComponents}
          endReached={handleReachedEnd}
          fixedHeaderContent={() => (
            <TableRow>
              <TableCell
                sx={{
                  width: 150,
                  backgroundColor: '#FFFFFF',
                }}
              >
                <AppTableSortLabel
                  orderByField={orderBy.field ?? ''}
                  orderByDirection={orderBy.direction ?? ''}
                  columnName={'CompanyName'}
                  columnLabel={'Company'}
                  handleSortColumn={handleSortColumn}
                />
              </TableCell>
              <TableCell
                align='right'
                sx={{
                  width: 175,
                  backgroundColor: '#FFFFFF',
                }}
              >
                <AppTableSortLabel
                  orderByField={orderBy.field ?? ''}
                  orderByDirection={orderBy.direction ?? ''}
                  columnName={'RemainingPoint'}
                  columnLabel={'Remaining Points'}
                  handleSortColumn={handleSortColumn}
                />
              </TableCell>
              <TableCell sx={{ backgroundColor: '#FFFFFF', width: 175 }}>
                <AppTableSortLabel
                  orderByField={orderBy.field ?? ''}
                  orderByDirection={orderBy.direction ?? ''}
                  columnName={'SubscriptionStart'}
                  columnLabel={'Subscription Start'}
                  handleSortColumn={handleSortColumn}
                />
              </TableCell>
              <TableCell sx={{ backgroundColor: '#FFFFFF', width: 175 }}>
                <AppTableSortLabel
                  orderByField={orderBy.field ?? ''}
                  orderByDirection={orderBy.direction ?? ''}
                  columnName={'SubscriptionEnd'}
                  columnLabel={'Subscription End'}
                  handleSortColumn={handleSortColumn}
                />
              </TableCell>
            </TableRow>
          )}
          itemContent={(idx, item) => (
            <React.Fragment key={item?.TenantId || idx}>
              <>
                <TableCell sx={{ minWidth: 250, maxWidth: 250 }}>
                  {item.CompanyId ? (
                    <NavLink
                      className='MuiDataGrid-cellContent'
                      to={`/company/${item.CompanyId}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Tooltip title={item?.CompanyName}>
                        <Typography
                          variant='body2'
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item?.CompanyName}
                        </Typography>
                      </Tooltip>
                    </NavLink>
                  ) : (
                    item?.CompanyName
                  )}
                </TableCell>
                <TableCell align='right'>
                  {formatNumber(item?.RemainingPoint ?? 0)}
                </TableCell>
                <TableCell>
                  {item?.SubscriptionStart
                    ? formatDateFromISO(item?.SubscriptionStart)
                    : ''}
                </TableCell>
                <TableCell>
                  {item?.SubscriptionEnd
                    ? formatDateFromISO(item?.SubscriptionEnd)
                    : ''}
                </TableCell>
              </>
            </React.Fragment>
          )}
        />
      ) : (
        <TableChart rows={[]} columns={columns} uniqueProp={''} />
      )}
    </AppCard>
  );
};

export default React.memo(ReachExpiryStatistic);
