import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts';
import {
  chartDateFormatter,
  formatNumber,
  getFontSizeLabelChart,
} from '@tenant/utility/helper/Utils';
import { Box } from '@mui/system';
import { List } from 'react-content-loader';
import { POINT_TRANSACTION_TYPE_FILTER_ENUM } from '../constants';
import { Typography } from '@mui/material';

const CustomTooltip = ({ active, payload, label, dateGroupType }) => {
  if (active && payload?.length > 0) {
    return (
      <Box
        className='custom-tooltip'
        backgroundColor='white'
        p={2}
        sx={{ border: '1px solid #ddd' }}
      >
        <Box p={1} fontWeight={600}>
          {chartDateFormatter(label, dateGroupType)}
        </Box>
        {payload.map((e) => (
          <Box key={e.dataKey} p={1} color={e.fill}>
            {`${e.name} : ${formatNumber(e.payload[e.dataKey])}`}
          </Box>
        ))}
      </Box>
    );
  }
};

const PointConsumptionChart = ({
  data = [],
  dateGroupType,
  isLoading,
  transactionType,
}) => {
  const [inActiveSeries, setInActiveSeries] = useState([]);
  const [strokeWidth, setStrokeWidth] = useState({});

  const encodeData = useMemo(() => {
    const keyName =
      typeof transactionType !== 'undefined'
        ? Object.keys(POINT_TRANSACTION_TYPE_FILTER_ENUM).find(
            (e) => POINT_TRANSACTION_TYPE_FILTER_ENUM[e] === transactionType,
          )
        : null;

    return keyName
      ? data.map((e) => ({ Key: e.Key, [keyName]: e?.[keyName] }))
      : data;
  }, [transactionType, data]);

  const isShowTotalPoint = typeof transactionType === 'undefined';
  const isShowPurchased =
    typeof transactionType === 'undefined' ||
    transactionType === POINT_TRANSACTION_TYPE_FILTER_ENUM['Purchased'];
  const isShowRefunded =
    typeof transactionType === 'undefined' ||
    transactionType === POINT_TRANSACTION_TYPE_FILTER_ENUM['Refunded'];
  const isShowBurned =
    typeof transactionType === 'undefined' ||
    transactionType === POINT_TRANSACTION_TYPE_FILTER_ENUM['Burned'];

  const handleLegendClick = (props) => {
    const { dataKey } = props ?? {};
    if (inActiveSeries.includes(dataKey)) {
      setInActiveSeries(inActiveSeries.filter((el) => el !== dataKey));
    } else {
      setInActiveSeries((prev) => [...prev, dataKey]);
    }
  };

  const handleMouseEnter = (props) => {
    const { dataKey } = props ?? {};

    setStrokeWidth((op) => ({ ...op, [dataKey]: 2 }));
  };

  const handleMouseLeave = (props) => {
    const { dataKey } = props ?? {};

    setStrokeWidth((op) => ({ ...op, [dataKey]: 1 }));
  };

  return isLoading ? (
    <Box height={350}>
      <List />
    </Box>
  ) : (
    <ResponsiveContainer width='100%' height={350}>
      <LineChart
        data={encodeData ?? []}
        margin={{
          top: 20,
          right: 30,
          left: 25,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' stroke='#eaeaea' />

        <XAxis
          dataKey='Key'
          tick={{
            fontSize: getFontSizeLabelChart(encodeData?.length ?? 0),
          }}
          tickFormatter={(value) => chartDateFormatter(value, dateGroupType)}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => formatNumber(value)}
        />
        {encodeData.length > 0 && (
          <Tooltip content={<CustomTooltip dateGroupType={dateGroupType} />} />
        )}

        <Legend
          wrapperStyle={{ bottom: '-5px' }}
          onClick={handleLegendClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />

        {isShowTotalPoint && (
          <Line
            hide={inActiveSeries.includes('TotalPoint')}
            strokeOpacity={strokeWidth.TotalPoint}
            dataKey='TotalPoint'
            type='monotone'
            name='Total Point'
            fill='#0a8fdc'
            stroke='#0a8fdc'
            dot={false}
          />
        )}

        {isShowPurchased && (
          <Line
            hide={inActiveSeries.includes('Purchased')}
            strokeOpacity={strokeWidth.Purchased}
            dataKey='Purchased'
            type='monotone'
            name='Purchased'
            fill='#28B463'
            stroke='#28B463'
            dot={false}
          />
        )}

        {isShowRefunded && (
          <Line
            hide={inActiveSeries.includes('Refunded')}
            strokeOpacity={strokeWidth.Refunded}
            dataKey='Refunded'
            type='monotone'
            name='Refunded'
            fill='#8884d8'
            stroke='#8884d8'
            dot={false}
          />
        )}

        {isShowBurned && (
          <Line
            hide={inActiveSeries.includes('Burned')}
            strokeOpacity={strokeWidth.Burned}
            dataKey='Burned'
            type='monotone'
            name='Burned'
            fill='#dd502f'
            stroke='#dd502f'
            dot={false}
          />
        )}

        {isShowTotalPoint && (
          <Line
            hide={inActiveSeries.includes('TotalRemaining')}
            strokeWidth={strokeWidth.TotalRemaining}
            dataKey='TotalRemaining'
            type='monotone'
            name='Remaining'
            fill='#f1c232'
            stroke='#f1c232'
            dot={false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default React.memo(PointConsumptionChart);

PointConsumptionChart.propTypes = {
  data: PropTypes.array,
  dateGroupType: PropTypes.number,
  isLoading: PropTypes.bool,
  transactionType: PropTypes.number,
};
