import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  Star as StarIcon,
  StarBorder as StarBorderIcon,
} from '@mui/icons-material';
import { setCurrentValueTenant, setIsInit } from '../../../../../redux/actions';
import AddFormModel from '../../formModel/AddFormModel';
import { InputField } from '../../formFields';
import { useIntl } from 'react-intl';
import useContractService from '../../hooks/useContractService';
import AppLoader from '@tenant/core/AppLoader';
import IntlMessages from '../../../../../@tenant/utility/IntlMessages';

let timeOut;
const DEFAULT_TIMEOUT = 300;

const TenantsGroups = (props) => {
  const {
    formValue,
    formikRef,
    isCreate = true,
    isEdit = false,
    setFieldError,
    setFieldValue,
    errors,
  } = props;
  const [loading, setLoading] = useState(false);
  const [userCountByTenants, setUserCountByTenants] = useState([]);
  const { getUsersByTenantId } = useContractService();

  const { formDetail, formField, formConfig } = AddFormModel;
  const {
    Tenant,
    TenantId,
    ContactName,
    VAT,
    Country,
    XeroContactEmail,
    XeroContactId,
    TrackerId,
  } = formField;

  const dispatch = useDispatch();
  const { messages } = useIntl();

  const isInit = useSelector(({ company }) => company.isInit);
  const currentTenant = useSelector(({ company }) => company.currTenant);

  const [primaryTenant, setPrimaryTenant] = useState(null);
  const [isCreateNew, setIsCreateNew] = useState(false);

  const handleChangeMaximumNumberOfUsers = (event, index) => {
    setFieldValue(
      `Tenants[${index}].Tenant.MaximumNumberUser`,
      event.target.value,
    );

    const validation = () => {
      const parsedValue = event.target.value
        ? parseInt(event.target.value)
        : event.target.value;
      const freeUsers = parseInt(formValue.Tenants[index].Tenant.FreeUser);

      if (parsedValue <= freeUsers) {
        setFieldError(
          `Tenants[${index}].Tenant.MaximumNumberUser`,
          formField.MaximumNumberUser.lessMaxUserErrorMsg,
        );
      }
    };

    clearTimeout(timeOut);
    timeOut = setTimeout(validation, DEFAULT_TIMEOUT);
  };

  const handleChangeFreeUsers = (event, index) => {
    setFieldValue(`Tenants[${index}].Tenant.FreeUser`, event.target.value);

    const validation = () => {
      const parsedValue = event.target.value
        ? parseInt(event.target.value)
        : event.target.value;
      const maximumUsers = parseInt(
        formValue.Tenants[index].Tenant.MaximumNumberUser,
      );

      if (parsedValue >= maximumUsers) {
        setFieldError(
          `Tenants[${index}].Tenant.FreeUser`,
          formField.NumberOfFreeUsers.lessMaxUserErrorMsg,
        );
      }
    };

    clearTimeout(timeOut);
    timeOut = setTimeout(validation, DEFAULT_TIMEOUT);
  };

  const handleUpdateContract = (data) => {
    setFieldValue(`${formDetail}.${0}.${Tenant.name}`, data);
    setFieldValue(`${formDetail}.${0}.${TenantId.name}`, data.TenantId);
    setFieldValue(`${formDetail}.${0}.${ContactName.name}`, data.ContactName);
    setFieldValue(`${formDetail}.${0}.${VAT.name}`, data.Vat);
    setFieldValue(`${formDetail}.${0}.${Country.name}`, data.Country);
    setFieldValue(
      `${formDetail}.${0}.${XeroContactEmail.name}`,
      data.XeroContactEmail,
    );
    setFieldValue(
      `${formDetail}.${0}.${XeroContactId.name}`,
      data.XeroContactId ?? '',
    );
    setFieldValue(`${formDetail}.${0}.${TrackerId.name}`, data.TrackerId ?? '');

    setFieldValue(`${formConfig}.${0}.${TenantId.name}`, data.TenantId);
    setFieldValue(`${formConfig}.${0}.${Tenant.name}`, data);
  };

  // Change primary tenant for each contract
  const handleChangeIsPrimary = (data, index) => {
    const primaryIndex = formValue.Tenants.findIndex(
      (item) => item.TenantId === currentTenant.TenantId,
    );
    dispatch(setCurrentValueTenant({ ...data, IsPrimary: true }));
    // update value in contract details
    setFieldValue(`Tenants[${primaryIndex}].${Tenant.name}.IsPrimary`, false);
    setFieldValue(`Tenants[${index}].${Tenant.name}.IsPrimary`, true);
    handleUpdateContract(data);
  };

  useEffect(() => {
    setIsCreateNew(isInit);
  }, [isInit]);

  useEffect(() => {
    if (isCreate && currentTenant) {
      // only for create/add new case
      setPrimaryTenant(currentTenant);
    } else {
      const foundTenant = formValue.Tenants?.find(
        ({ Tenant }) => Tenant.IsPrimary,
      );

      if (foundTenant?.Tenant) {
        setPrimaryTenant({ ...foundTenant.Tenant });
      }
    }
  }, [formValue]);

  useEffect(() => {
    // this is used to map value in create contract
    if (isCreateNew && formValue?.Tenants[0].TenantId !== '') {
      dispatch(setIsInit(false));
      setIsCreateNew(false);
      const index = formValue.Tenants.findIndex(
        (item) => item.TenantId === currentTenant.TenantId,
      );
      for (const [index, { Tenant }] of formValue.Tenants.entries()) {
        setFieldValue(`Tenants[${index}].Tenant.MaximumNumberUser`, 0);
        setFieldValue(`Tenants[${index}].Tenant.FreeUser`, 0);
      }
      setFieldValue(`Tenants[${index}].${Tenant.name}.IsPrimary`, true);
    }
  }, [isCreateNew]);

  const getErrorMaximumNumberUser = ({ errors, userCount, value }) => {
    if (errors?.Tenant?.MaximumNumberUser) {
      return errors?.Tenant?.MaximumNumberUser;
    }
    return value && value < userCount
      ? messages['contract.edit.maxUserNumberWithActiveUser']
      : undefined;
  };

  const handleBlurMaxNumber = (tenantId) => {
    if (!tenantId || userCountByTenants.some((e) => e.TenantId === tenantId)) {
      return;
    }
    setLoading(true);
    getUsersByTenantId({
      payload: { TenantId: tenantId },
      onSuccess: (data) => {
        if (typeof data?.length === 'number') {
          setUserCountByTenants((s) => [
            ...s,
            {
              ActiveUserCount: data.length + 1,
              TenantId: tenantId,
            },
          ]);
        }
      },
      onFinally: () => setLoading(false),
    });
  };

  const headerColumns = useMemo(() => {
    if (!isCreate && !isEdit) {
      return (
        <TableRow>
          <TableCell width='20%' key='tenant-name'>
            Tenant Name
          </TableCell>
          <TableCell width='20%' key='max-users'>
            Maximum Number Of Users
          </TableCell>
          <TableCell width='20%' key='free-users'>
            Number Of Free Users
          </TableCell>
          <TableCell width='10%' key='primary'>
            Is Primary
          </TableCell>
          {!isCreate && !isEdit && (
            <TableCell width='15%' key='multi-tenancy-status'>
              <IntlMessages id='contractLayer.multiTenancyStatus' />
            </TableCell>
          )}
          {!isCreate && !isEdit && (
            <TableCell width='15%' key='customer-portal-tenancy-status'>
              <IntlMessages id='contractLayer.customerPortalTenancyStatus' />
          </TableCell>
          )}
        </TableRow>
      );
    } else {
      return (
        <TableRow>
          <TableCell width='30%' key='tenant-name'>
            Tenant Name
          </TableCell>
          <TableCell width='30%' key='max-users'>
            Maximum Number Of Users
          </TableCell>
          <TableCell width='30%' key='free-users'>
            Number Of Free Users
          </TableCell>
          <TableCell width='10%' key='primary'>
            Is Primary
          </TableCell>
        </TableRow>
      );
    }
  }, [isCreate, isEdit]);

  return (
    <TableContainer component={Paper}>
      {loading && <AppLoader />}
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>{headerColumns}</TableHead>
        <TableBody>
          {formValue?.Tenants?.length > 0 ? (
            formValue?.Tenants?.map((row, index) => (
              <TableRow
                key={`${row.Tenant?.TenantId}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {row.Tenant?.ContactName}
                </TableCell>
                <TableCell>
                  {isCreate ? (
                    <InputField
                      fullWidth
                      type='number'
                      name={`Tenants[${index}].Tenant.MaximumNumberUser`}
                      error={
                        !!getErrorMaximumNumberUser({
                          errors: errors.Tenants?.[index],
                          userCount:
                            userCountByTenants.find(
                              (e) => e.TenantId === row.Tenant?.TenantId,
                            )?.ActiveUserCount ?? 0,
                          value: row.Tenant?.MaximumNumberUser,
                        })
                      }
                      helperText={getErrorMaximumNumberUser({
                        errors: errors.Tenants?.[index],
                        userCount:
                          userCountByTenants.find(
                            (e) => e.TenantId === row.Tenant?.TenantId,
                          )?.ActiveUserCount ?? 0,
                        value: row.Tenant?.MaximumNumberUser,
                      })}
                      value={row.Tenant?.MaximumNumberUser}
                      onChange={(e) =>
                        handleChangeMaximumNumberOfUsers(e, index)
                      }
                      onBlur={(e) => {
                        handleBlurMaxNumber(row.Tenant?.TenantId);
                        handleChangeMaximumNumberOfUsers(e, index);
                      }}
                    />
                  ) : (
                    <Typography sx={{ margin: '0 5rem' }} component='span'>
                      {row.Tenant?.MaximumNumberUser}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {isCreate ? (
                    <InputField
                      fullWidth
                      type='number'
                      name={`Tenants[${index}].Tenant.FreeUser`}
                      error={!!errors.Tenants?.[index]?.Tenant?.FreeUser}
                      helperText={errors.Tenants?.[index]?.Tenant?.FreeUser}
                      value={row.Tenant.FreeUser}
                      onChange={(e) => handleChangeFreeUsers(e, index)}
                      onBlur={(e) => handleChangeFreeUsers(e, index)}
                    />
                  ) : (
                    <Typography sx={{ margin: '0 3rem' }} component='span'>
                      {row.Tenant.FreeUser}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {isCreate && !isEdit ? (
                    <Tooltip title='Change Primary'>
                      <IconButton
                        color='primary'
                        edge='end'
                        aria-label='change-primary'
                        onClick={() => {
                          handleChangeIsPrimary(row.Tenant, index);
                        }}
                      >
                        {row.Tenant.TenantId === primaryTenant?.TenantId &&
                        primaryTenant?.IsPrimary ? (
                          <StarIcon />
                        ) : (
                          <StarBorderIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Grid sx={{ margin: '0 20px' }}>
                      {row.Tenant.TenantId === primaryTenant?.TenantId &&
                      primaryTenant?.IsPrimary ? (
                        <StarIcon color='primary' />
                      ) : (
                        <StarBorderIcon color='primary' />
                      )}
                    </Grid>
                  )}
                </TableCell>
                {!isCreate && !isEdit && (
                  <TableCell>
                    <Typography component='span'>
                      {row.Tenant?.MultiTenancyBackOfficeStatus}
                    </Typography>
                  </TableCell>
                )}
                {!isCreate && !isEdit && (
                  <TableCell>
                    <Typography component='span'>
                      {row.Tenant?.MultiTenancyPortalStatus}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableCell colSpan={4}>
              <Typography
                component='div'
                sx={{ fontSize: 16, textAlign: 'center' }}
              >
                No tenants found
              </Typography>
            </TableCell>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(TenantsGroups);

TenantsGroups.propTypes = {
  formValue: PropTypes.object,
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
  formikRef: PropTypes.object,
  setFieldError: PropTypes.func,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
};
