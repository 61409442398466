import React, { useMemo } from 'react';
import List from '@mui/material/List';

import routesConfig from '../../../../../pages/routesConfig';
import NavVerticalGroup from './VerticalNavGroup';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import { useSelector } from 'react-redux';

const checkAccessPermission = (route, userPermissions = []) => {
  return (
    !route.permissionIds ||
    userPermissions.some((permission) =>
      route.permissionIds.some(
        (permissionId) => permissionId === permission.PermissionId,
      ),
    )
  );
};

const VerticalNav = () => {
  const { user, mainFeatures } = useSelector((s) => s.authorization);

  const routesConfigWithFeatures = useMemo(() => {
    if (mainFeatures?.length > 0) {
      const data = routesConfig.map((route) => ({
        ...route,
        children: route?.children.map((child) =>
          child.id === 'features'
            ? {
                ...child,
                children: mainFeatures.map((feature) => ({
                  id: feature.Id + '',
                  title: feature.FeatureName,
                  type: 'item',
                  url: '/dashboards/features/' + feature.Id,
                })),
              }
            : child,
        ),
      }));

      return data;
    }
    return routesConfig;
  }, [mainFeatures]);

  const routeWithPermissions = useMemo(
    () =>
      routesConfigWithFeatures
        .filter((group) =>
          checkAccessPermission(group, user?.Permissions ?? []),
        )
        .map((group) => ({
          ...group,
          children: group.children.filter((route) =>
            checkAccessPermission(route, user?.Permissions ?? []),
          ),
        })),
    [user?.Permissions, routesConfigWithFeatures],
  );

  const newRoutesConfig = useMemo(
    () =>
      !user.IsPermissionManager
        ? routeWithPermissions
            .filter((route) => !route.isPermissionManager)
            .map((route) => ({
              ...route,
              children: route.children.filter((e) => !e.isPermissionManager),
            }))
        : routeWithPermissions,
    [user?.IsPermissionManager, routeWithPermissions],
  );

  return (
    <List
      sx={{
        position: 'relative',
        padding: 0,
      }}
      component='div'
    >
      {newRoutesConfig.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && <NavVerticalGroup item={item} level={0} />}

          {item.type === 'collapse' && (
            <VerticalCollapse
              item={item}
              level={0}
              defaultOpen={item.defaultOpen}
            />
          )}

          {item.type === 'item' && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default VerticalNav;
