import { NavLink } from 'react-router-dom';
import { Tooltip, Typography } from '@mui/material';
import { formatDate } from '@tenant/utility/helper/DateHelper';
import moment from 'moment';
import {
  PAYMENT_METHOD_ENUM,
  SUBSCRIPTION_STATUS_ENUM,
  PACKAGE_TYPE_ENUM,
} from '@tenant/utility/constants/enum';
import { MULTI_TENANCY_STATUS } from '../../../tenantDetail/constants';

export const getTableColumns = () => {
  return [
    {
      field: 'Name',
      headerName: 'Company Name',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <NavLink
            className='MuiDataGrid-cellContent'
            to={`/company/${params.row.Id}`}
            style={{ textDecoration: 'none' }}
          >
            {params.value}
          </NavLink>
        </Tooltip>
      ),
    },
    {
      field: 'NumberOfFreeUsers',
      headerName: 'Number of Free Users',
      sortable: false,
      type: 'number',
      minWidth: '150',
      renderCell: (params) => (
        <Typography variant='body2'>{params.value}</Typography>
      ),
    },
    {
      field: 'NumberContract',
      headerName: 'Number of Contract',
      sortable: false,
      type: 'number',
      minWidth: '150',
      renderCell: (params) => (
        <Typography variant='body2'>{params.value}</Typography>
      ),
    },
    {
      field: 'PaymentMethod',
      headerName: 'Payment Method',
      sortable: false,
      minWidth: '150',
      renderCell: (params) => (
        <Typography variant='body2'>{params.value}</Typography>
      ),
    },
    {
      field: 'SubscriptionStart',
      headerName: 'Subscription Start',
      type: 'date',
      sortable: false,
      align: 'right',
      minWidth: '150',
      headerAlign: 'right',
      renderCell: (params) => (
        <Typography variant='body2'>
          {formatDate(moment(params.value))}
        </Typography>
      ),
    },
    {
      field: 'SubscriptionEnd',
      headerName: 'Subscription End',
      type: 'date',
      sortable: false,
      align: 'right',
      minWidth: '150',
      headerAlign: 'right',
      renderCell: (params) => (
        <Typography variant='body2'>
          {formatDate(moment(params.value))}
        </Typography>
      ),
    },
    {
      field: 'CreatedAt',
      headerName: 'Created Date',
      type: 'date',
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      minWidth: '150',
      renderCell: (params) => (
        <Typography variant='body2'>
          {formatDate(moment(params.value))}
        </Typography>
      ),
    },
  ];
};

export const processInputFilterCompanyList = (filter) => {
  return typeof filter === 'object'
    ? {
        ...filter,
        totalCount: undefined,
        pageIndex: undefined,
        searchTerm: undefined,
        pageSize: undefined,
        SearchTerm: filter.searchTerm,
        PageIndex: filter.pageIndex,
        PageSize: filter.pageSize,
        HavingAccessToTypes: filter.havingAccessToTypes ?? [],
        NumberOfFreeUsers:
          filter.NumberOfFreeUsers?.Value ||
          filter.NumberOfFreeUsers?.Value === 0
            ? filter.NumberOfFreeUsers
            : undefined,
        NumberOfMaxUsers:
          filter.NumberOfMaxUsers?.Value || filter.NumberOfMaxUsers?.Value === 0
            ? filter.NumberOfMaxUsers
            : undefined,
        PaymentMethod: filter.PaymentMethod
          ? PAYMENT_METHOD_ENUM[filter.PaymentMethod]
          : undefined,
        Country: filter.Country?.Country,
        SubscriptionStatus: filter.SubscriptionStatus
          ? SUBSCRIPTION_STATUS_ENUM[filter.SubscriptionStatus]
          : undefined,
        SubscriptionEndFrom: filter.SubscriptionEndFrom
          ? formatDate(filter.SubscriptionEndFrom)
          : undefined,
        SubscriptionEndTo: filter.SubscriptionEndTo
          ? formatDate(filter.SubscriptionEndTo)
          : undefined,
        SubscriptionStatuses:
          filter.SubscriptionStatuses?.length > 0
            ? filter.SubscriptionStatuses.map(
                (e) => SUBSCRIPTION_STATUS_ENUM[e],
              )
            : undefined,
        PaymentMethods:
          filter.PaymentMethods?.length > 0
            ? filter.PaymentMethods.map((e) => PAYMENT_METHOD_ENUM[e])
            : undefined,
        MultiTenantTypes:
          filter.MultiTenantTypes?.length > 0
            ? filter.MultiTenantTypes.map((e) => e.Id)
            : undefined,
        Countries:
          filter.Countries?.length > 0
            ? filter.Countries.map((e) => e.Country)
            : undefined,
        PackageTypes:
          filter.PackageTypes?.length > 0
            ? filter.PackageTypes.map((e) => PACKAGE_TYPE_ENUM[e])
            : undefined,
        SubscriptionStart: filter.SubscriptionStart?.Value
          ? {
              Operator: filter.SubscriptionStart?.Operator,
              Value: formatDate(filter.SubscriptionStart?.Value),
            }
          : undefined,
        SubscriptionEnd: filter.SubscriptionEnd?.Value
          ? {
              Operator: filter.SubscriptionEnd?.Operator,
              Value: formatDate(filter.SubscriptionEnd?.Value),
            }
          : undefined,
        SalesPersonIds:
          filter.SalesPersonIds?.length > 0
            ? filter.SalesPersonIds.map((e) => e.Id)
            : undefined,
        AccountManagerIds:
          filter.AccountManagerIds?.length > 0
            ? filter.AccountManagerIds.map((e) => e.Id)
            : undefined,
        CustomerJourneyIds:
          filter.CustomerJourneyIds?.length > 0
            ? filter.CustomerJourneyIds.map((e) => e.Value)
            : undefined,
      }
    : filter;
};

export const getNewValueFromAudit = (
  currentValue = 0,
  changeValue = 0,
  type = 0,
) => {
  const isAdd = type === 1;
  const isRemove = type === 2;

  if (isAdd) {
    return +(currentValue ?? 0) + +(changeValue ?? 0);
  }

  if (isRemove) {
    return +(currentValue ?? 0) - +(changeValue ?? 0);
  }

  return currentValue;
};

export const getAmountWithDiscount = (total, discount, isPercent) => {
  const convertTotal = total ?? 0;
  const convertDiscount = discount ?? 0;
  return isPercent
    ? convertTotal - convertTotal * (convertDiscount / 100)
    : convertTotal - convertDiscount;
};

export const getAmountWithTax = (total, tax) => {
  const convertTotal = total ?? 0;
  const convertTax = tax ?? 0;
  return convertTotal + convertTotal * (convertTax / 100);
};
