import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Pagination,
  Skeleton,
  Tooltip,
  Typography,
  debounce,
  useMediaQuery,
} from '@mui/material';
import { POINT_TRANSACTION_TYPE_FILTER_ENUM } from '../constants';
import {
  formatNumber,
  getRangeDateByKeyName,
} from '@tenant/utility/helper/Utils';
import AppCard from '@tenant/core/AppCard';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { DataGrid } from '@mui/x-data-grid';
import { apiQueryInceptors } from '@tenant/utility/helper/ApiHelper';
import { API } from '../constants';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { RANGE_DATE } from 'pages/tenantDetail/constants';
import {
  DEFAULT_PAGE_INDEX,
  dateFormatByKey,
} from '@tenant/utility/constants/default';
import PointConsumptionChart from './PointConsumptionChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableViewIcon from '@mui/icons-material/TableView';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { ORDER_DIRECTION } from '@tenant/utility/constants/enum';
import AppGlossary from '@tenant/core/App/Common/AppGlossary';
import { makeColTotalStyle } from '../styles';
import { useTheme } from '@emotion/react';

const PAGE_SIZE = 10;

const PointConsumption = () => {
  const [selectedType, setSelectedType] = useState(RANGE_DATE.THIS_YEAR);
  const [dateRangeSelect, setDateRangeSelect] = useState([]);
  const [dateGroupType, setDateGroupType] = useState(
    dateFormatByKey[RANGE_DATE.THIS_YEAR]?.groupType,
  );
  const [total, setTotal] = useState();
  const [isTableView, setIsTableView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadedReport, setLoadedReport] = useState(false);
  const [loadedList, setLoadedList] = useState(false);
  const [data, setData] = useState([]);
  const [dataReport, setDataReport] = useState([]);
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [totalCount, setTotalCount] = useState(0);
  const [keyRerenderTable, setKeyRerenderTable] = useState(0);
  const [transactionType, setTransactionType] = useState();
  const [orderBy, setOrderBy] = useState({});
  const theme = useTheme();
  const isFlexTable = useMediaQuery(theme.breakpoints.up('md'));

  const columns = useMemo(
    () => [
      {
        field: 'CompanyName',
        headerName: 'Company Name',
        flex: isFlexTable ? 1 : undefined,
        width: 300,
        sortable: true,
        renderCell: (params) => {
          const renderElement = (params) => (
            <Tooltip title={params.value ?? '-'}>
              <Typography
                variant='body2'
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {params.value ?? '-'}
              </Typography>
            </Tooltip>
          );

          return params.row.CompanyId ? (
            <Link
              to={'/company/' + params.row.CompanyId}
              style={{ textDecoration: 'none' }}
            >
              {renderElement(params)}
            </Link>
          ) : (
            renderElement(params)
          );
        },
      },
      {
        field: 'TotalPoint',
        headerName: 'Total Point',
        type: 'number',
        width: 125,
        renderCell: (params) => {
          return (
            <Typography variant='body2' color={'primary.main'}>
              {formatNumber?.(params.value) || params.value}
            </Typography>
          );
        },
      },
      {
        field: 'Purchased',
        headerName: 'Purchased',
        type: 'number',
        width: 125,
        renderCell: (params) => (
          <Typography variant='body2' color={'success.main'}>
            {formatNumber?.(params.value) || params.value}
          </Typography>
        ),
      },
      {
        field: 'Refunded',
        headerName: 'Refunded',
        type: 'number',
        width: 125,
        renderCell: (params) => (
          <Typography variant='body2'>
            {formatNumber?.(params.value) || params.value}
          </Typography>
        ),
      },
      {
        field: 'Burned',
        headerName: 'Burned',
        type: 'number',
        width: 125,
        renderCell: (params) => (
          <Typography variant='body2' color={'error.main'}>
            {formatNumber?.(params.value) || params.value}
          </Typography>
        ),
      },
      {
        field: 'Remaining',
        headerName: 'Remaining',
        type: 'number',
        width: 125,
        renderCell: (params) => (
          <Typography variant='body2' component={'strong'}>
            {formatNumber?.(params.value) || params.value}
          </Typography>
        ),
      },
    ],
    [isFlexTable],
  );

  const getListData = useCallback((payload = {}) => {
    const {
      startDate,
      endDate,
      transactionType,
      field,
      direction,
      pageIndex = 1,
      signal,
    } = payload;
    setLoading(true);

    apiQueryInceptors({
      apiFnc: () =>
        jwtAxios.get(API.GET_TRANSACTION, {
          signal,
          params: {
            startDate: startDate,
            endDate: endDate,
            pageSize: PAGE_SIZE,
            transactionType,
            pageIndex,
            field,
            direction,
          },
        }),
      onSuccess: (data) => {
        setData(deepClone(data?.ObjectData?.Items));
        setTotal(deepClone(data?.ObjectData));
        setTotalCount(data?.ObjectData?.Total);
        setLoadedList(true);
      },
      onError: () => {},
      onFinally: () => {
        setLoading(false);
      },
    });
  }, []);

  const getReportData = useCallback((payload = {}) => {
    const { startDate, endDate, dateGroupType, transactionType, pageIndex } =
      payload;
    setLoading(true);

    apiQueryInceptors({
      apiFnc: () =>
        jwtAxios.get(API.GET_TRANSACTION_STATISTIC, {
          params: {
            startDate,
            endDate,
            dateGroupType,
            pageIndex,
            transactionType,
          },
        }),
      onSuccess: (data) => {
        setDataReport(deepClone(data?.Items));
        setTotal(deepClone(data));
        setLoadedReport(true);
      },
      onError: () => {},
      onFinally: () => {
        setLoading(false);
      },
    });
  }, []);

  const findEndpointToRequest = useCallback(
    ({
      dateGroupType,
      startDate,
      endDate,
      transactionType,
      field,
      direction,
      pageIndex = 1,
    }) => {
      setLoadedReport(false);
      setLoadedList(false);
      setPageIndex(pageIndex);

      if (isTableView) {
        getListData({
          startDate,
          endDate,
          dateGroupType,
          pageIndex,
          transactionType,
          field,
          direction,
        });
        return;
      }

      getReportData({
        startDate,
        endDate,
        dateGroupType,
        transactionType,
      });
    },
    [isTableView],
  );

  useEffect(() => {
    if (loadedList) {
      return;
    }
    const controller = new AbortController();

    const { start, end, dateGroupType } = getRangeDateByKeyName(
      RANGE_DATE.THIS_YEAR,
    );
    setDateRangeSelect([start, end]);
    setDateGroupType(dateGroupType);

    getListData({
      startDate: start,
      endDate: end,
      dateGroupType,
      pageIndex,
      transactionType,
      signal: controller.signal,
    });

    return () => controller.abort();
  }, [getListData]);

  const onChangeTab = (isTableView) => {
    setIsTableView(isTableView);

    !isTableView &&
      !loadedReport &&
      getReportData({
        startDate: dateRangeSelect[0],
        endDate: dateRangeSelect[1],
        dateGroupType,
        transactionType,
      });

    isTableView &&
      !loadedList &&
      getListData({
        startDate: dateRangeSelect[0],
        endDate: dateRangeSelect[1],
        dateGroupType,
        transactionType,
        ...orderBy,
      });
  };

  const handleChangeValue = (data) => {
    const { startDate, endDate, dateGroupType, keyName } = data || {};
    setDateGroupType(dateGroupType);
    setSelectedType(keyName);
    setDateRangeSelect([startDate, endDate]);

    findEndpointToRequest({
      startDate,
      endDate,
      dateGroupType,
      transactionType,
      field: orderBy?.field,
      direction: orderBy?.direction,
    });
  };

  const onChangePage = (event, newPage) => {
    setPageIndex(newPage);
    findEndpointToRequest({
      pageIndex: newPage,
      startDate: dateRangeSelect[0],
      endDate: dateRangeSelect[1],
      dateGroupType,
      transactionType,
      field: orderBy?.field,
      direction: orderBy?.direction,
    });
  };

  const onChangeTransactionType = (transactionType) => {
    const findTransactionTypeKey = Object.keys(
      POINT_TRANSACTION_TYPE_FILTER_ENUM,
    ).find((e) => POINT_TRANSACTION_TYPE_FILTER_ENUM[e] === transactionType);

    const isResetOrderBy =
      (orderBy?.field === 'Purchased' &&
        findTransactionTypeKey !== 'Purchased') ||
      (orderBy?.field === 'Refunded' &&
        findTransactionTypeKey !== 'Refunded') ||
      (orderBy?.field === 'Burned' && findTransactionTypeKey !== 'Burned');

    const newOrderBy = isResetOrderBy ? {} : orderBy;
    isResetOrderBy && setKeyRerenderTable((s) => s + 1);

    setTransactionType(transactionType);
    setOrderBy(newOrderBy);

    findEndpointToRequest({
      dateGroupType,
      startDate: dateRangeSelect[0],
      endDate: dateRangeSelect[1],
      transactionType,
      field: newOrderBy?.field,
      direction: newOrderBy?.direction,
    });
  };

  const handleSortColumn = debounce((sortModel) => {
    const { field, sort } = sortModel?.[0] ?? {};
    let currentTransactionType = transactionType;

    if (currentTransactionType) {
      const findTransactionTypeKey = Object.keys(
        POINT_TRANSACTION_TYPE_FILTER_ENUM,
      ).find((e) => POINT_TRANSACTION_TYPE_FILTER_ENUM[e] === transactionType);
      currentTransactionType =
        (field === 'Purchased' && findTransactionTypeKey !== 'Purchased') ||
        (field === 'Refunded' && findTransactionTypeKey !== 'Refunded') ||
        (field === 'Burned' && findTransactionTypeKey !== 'Burned')
          ? undefined
          : transactionType;

      typeof currentTransactionType === 'undefined' && setTransactionType();
    }

    setOrderBy({
      field: field,
      direction: ORDER_DIRECTION[sort],
    });

    setPageIndex(DEFAULT_PAGE_INDEX);

    const payload = {
      field: field,
      direction: ORDER_DIRECTION[sort],
      pageIndex: DEFAULT_PAGE_INDEX,
      startDate: dateRangeSelect[0],
      endDate: dateRangeSelect[1],
      transactionType: currentTransactionType,
    };

    getListData(payload);
  }, 500);

  return (
    <AppCard
      title={
        <Box display='flex' alignItems='center'>
          Points Consumption
        </Box>
      }
      action={
        <Box display={'flex'}>
          <Box mr={2}>
            <DateMenu
              defaultValue={selectedType}
              handleChangeValue={handleChangeValue}
            />
          </Box>
          <Box>
            <ButtonGroup variant='outlined' aria-label='Basic button group'>
              <Button
                variant={isTableView ? 'contained' : 'outlined'}
                onClick={() => onChangeTab(true)}
              >
                <TableViewIcon />
              </Button>
              <Button
                variant={!isTableView ? 'contained' : 'outlined'}
                onClick={() => onChangeTab(false)}
              >
                <BarChartIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      }
    >
      <Grid container sx={{ pointerEvents: loading && 'none' }}>
        <Grid item sm={12} md={10}>
          <Box mt={5} mb={5} sx={{ backgroundColor: '#f4f6f8', p: 2 }}>
            <Grid
              container
              pt={3}
              pb={3}
              paddingBlockStart={3}
              className='area-total'
              display={'flex'}
              justifyContent={'space-between'}
              mt={1}
              mb={1}
              fontSize={'1.5rem'}
              sx={makeColTotalStyle({ loading })}
            >
              <Grid
                item
                xs={12}
                sm={3}
                className={
                  'col-total ' +
                  (typeof transactionType === 'undefined' ||
                    (transactionType ===
                      POINT_TRANSACTION_TYPE_FILTER_ENUM['Purchased'] &&
                      'active'))
                }
                onClick={() =>
                  onChangeTransactionType(
                    POINT_TRANSACTION_TYPE_FILTER_ENUM['Purchased'],
                  )
                }
              >
                <Box className='col-title'>
                  Purchased{' '}
                  <AppGlossary title='The points have been purchased by companies.' />
                </Box>
                <Box className='col-count' color={'success.main'}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {typeof transactionType === 'undefined' ||
                      transactionType ===
                        POINT_TRANSACTION_TYPE_FILTER_ENUM['Purchased']
                        ? formatNumber(total?.TotalPurchased ?? 0)
                        : '-'}
                    </>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className={
                  'col-total ' +
                  (transactionType ===
                    POINT_TRANSACTION_TYPE_FILTER_ENUM['Refunded'] && 'active')
                }
                onClick={() =>
                  onChangeTransactionType(
                    POINT_TRANSACTION_TYPE_FILTER_ENUM['Refunded'],
                  )
                }
              >
                <Box className='col-title'>
                  Refunded{' '}
                  <AppGlossary title="The points have been refunded at the companies's request." />
                </Box>
                <Box className='col-count'>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {typeof transactionType === 'undefined' ||
                      transactionType ===
                        POINT_TRANSACTION_TYPE_FILTER_ENUM['Refunded']
                        ? formatNumber(total?.TotalRefunded ?? 0)
                        : '-'}
                    </>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className={
                  'col-total ' +
                  (typeof transactionType === 'undefined' && 'active')
                }
                onClick={() => onChangeTransactionType()}
              >
                <Box className='col-title'>
                  Total Points{' '}
                  <AppGlossary title='The quantity points reality companies bought.' />
                </Box>
                <Box className='col-count' color={'primary.main'}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {typeof transactionType === 'undefined'
                        ? formatNumber(total?.TotalPoint ?? 0)
                        : '-'}
                    </>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className={
                  'col-total ' +
                  (transactionType ===
                    POINT_TRANSACTION_TYPE_FILTER_ENUM['Burned'] && 'active')
                }
                onClick={() =>
                  onChangeTransactionType(
                    POINT_TRANSACTION_TYPE_FILTER_ENUM['Burned'],
                  )
                }
              >
                <Box className='col-title'>
                  Burned{' '}
                  <AppGlossary title='The points have been used by companies.' />
                </Box>
                <Box className='col-count' color={'error.main'}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {typeof transactionType === 'undefined' ||
                      transactionType ===
                        POINT_TRANSACTION_TYPE_FILTER_ENUM['Burned']
                        ? formatNumber(total?.TotalBurned ?? 0)
                        : '-'}
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item sm={12} md={2}>
          <Box
            mt={5}
            mb={5}
            sx={{
              backgroundColor: '#fff',
              p: 1.75,
              border: '1px dashed #ddd',
            }}
          >
            <Grid
              container
              pt={3}
              pb={3}
              paddingBlockStart={3}
              className='area-total'
              display={'flex'}
              justifyContent={'space-between'}
              mt={1}
              mb={1}
              fontSize={'1.5rem'}
              sx={makeColTotalStyle({ loading })}
            >
              <Grid item xs={12} md={12} className={'col-total non-action'}>
                <Box className='col-title' sx={{ color: '#f1c232 !important' }}>
                  Remaining
                  <AppGlossary title='The difference between the total points and burned.' />
                </Box>
                <Box className='col-count' color={'#f1c232'}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {typeof transactionType === 'undefined'
                        ? formatNumber(total?.TotalRemaining ?? 0)
                        : '-'}
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Box>
        {isTableView && (
          <>
            <Box mt={2} height={350}>
              <DataGrid
                key={keyRerenderTable}
                disableColumnMenu
                sortingOrder={['desc', 'asc']}
                hideFooter
                rows={data ?? []}
                columns={columns}
                loading={loading}
                getRowId={(row) => row.TransactionDate + uuidv4()}
                onSortModelChange={handleSortColumn}
                sortingMode='server'
              />
            </Box>
            <Box
              mt={5}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ pointerEvents: loading && 'none' }}
            >
              <Pagination
                showFirstButton
                showLastButton
                count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
                page={pageIndex}
                onChange={onChangePage}
              />
              <Typography ml={2}>Total: {total?.Total ?? 0}</Typography>
            </Box>
          </>
        )}

        {!isTableView && (
          <PointConsumptionChart
            data={dataReport}
            isLoading={loading}
            dateGroupType={dateGroupType}
            transactionType={transactionType}
          />
        )}
      </Box>
    </AppCard>
  );
};

export default React.memo(PointConsumption);

PointConsumption.propTypes = {};
