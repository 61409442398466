import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useAllowedAccess = ({ permissionIds, isPermissionManager }) => {
  const { user } = useSelector((s) => s.authorization);
  const { Permissions, IsPermissionManager } = user || {};
  const canAccess = useMemo(
    () =>
      isPermissionManager
        ? IsPermissionManager
        : Permissions?.some((permission) =>
            permissionIds.some(
              (permissionId) => permissionId === permission.PermissionId,
            ),
          ),
    [Permissions, permissionIds],
  );
  return canAccess;
};

export default useAllowedAccess;
