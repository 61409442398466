import React from 'react';
import {
  Grid,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  TableRow,
  Divider,
  ListItem,
  Typography,
  Checkbox,
  FormControlLabel,
  Tooltip,
  ListItemIcon,
} from '@mui/material';
import { useSelector } from 'react-redux';

import AppGridContainer from '@tenant/core/AppGridContainer';
import { Fonts } from 'shared/constants/AppEnums';
import { numberToCurrency } from '@tenant/utility/helper/Utils';
import {
  parseCredit,
  parseCreditAmount,
  parseDebitAmount,
} from 'pages/apps/ProductUsage/helpers';
import IntlMessages from '@tenant/utility/IntlMessages';

const StepConfirm = ({ formik, formData, handleChangeCheckbox }) => {
  const { contractSummary } = useSelector(({ company }) => company);
  const { values, setFieldValue } = formik;
  const {
    allBundles,
    totalPurchasedPoints,
    totalPrices,
    totalDiscount,
    totalPayment,
    taxAmount,
    discountedTotalPrices,
    isGenerateInvoice,
  } = formData;

  return (
    <AppGridContainer spacing={5}>
      <Grid
        item
        xs={12}
        sm={8}
        md={8}
        sx={{ overflow: 'auto', height: 'calc(100vh - 360px)', p: 4 }}
      >
        {!!allBundles.length && (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Bundle</TableCell>
                  <TableCell align='right'>Points</TableCell>
                  <TableCell align='right'>Prices</TableCell>
                  <TableCell align='right'>Quantity</TableCell>
                  <TableCell align='right'>Total Points</TableCell>
                  <TableCell align='right'>Total Prices</TableCell>
                  <TableCell align='right'>Discount Percent</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {allBundles.map((bundle, idx) => (
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                    key={idx}
                  >
                    <TableCell sx={{ width: 150, maxWidth: 150 }}>
                      {bundle?.Name}
                    </TableCell>
                    <TableCell align='right'>
                      {parseCredit(bundle?.Points ?? 0)}
                    </TableCell>
                    <TableCell align='right'>
                      {numberToCurrency(bundle?.Price ?? 0, 2, 'GBP')}
                    </TableCell>
                    <TableCell align='right'>{bundle?.Quantity}</TableCell>
                    <TableCell align='right'>
                      {parseCredit(bundle?.BundleTotalPoints ?? 0)}
                    </TableCell>
                    <TableCell align='right'>
                      {numberToCurrency(
                        bundle?.BundleTotalPrices ?? 0,
                        2,
                        'GBP',
                      )}
                    </TableCell>
                    <TableCell align='right'>
                      {bundle?.DiscountPercent ?? 0}%
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <FormControlLabel
          control={<Checkbox checked={isGenerateInvoice} color='warning' />}
          label={
            <Typography variant='h4'>
              <IntlMessages id='bundle.generateXeroInvoice' />
            </Typography>
          }
          onChange={(e) => handleChangeCheckbox(e.target.checked)}
        />

        <TextField
          fullWidth
          multiline
          sx={{
            width: '100%',
            fontWeight: Fonts.LIGHT,
            marginTop: 2,
          }}
          inputProps={{ maxLength: 255 }}
          rows='2'
          variant='outlined'
          label='Note'
          name='note'
          value={values.note}
          onChange={(e) => {
            setFieldValue('note', e.target.value);
          }}
        />
      </Grid>

      {/* Summary */}
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        p={2}
        sx={{
          height: 'calc(100vh - 360px)',
          overflow: 'auto',
        }}
      >
        <Stack direction='column' spacing={3} p={2}>
          <Stack
            direction='column'
            sx={{
              borderRadius: 2,
              border: '1px solid rgba(0,0,0,0.2)',
            }}
          >
            <Typography px={4} py={3} sx={{ fontSize: 18, fontWeight: 700 }}>
              {<IntlMessages id='bundle.purchaseSummary' />}
            </Typography>

            <ListItem sx={{ alignItems: 'baseline', gap: 4 }}>
              <ListItemIcon>
                <Typography fontWeight={500}>
                  <IntlMessages id='common.company' />
                </Typography>
              </ListItemIcon>
              <Tooltip
                title={contractSummary?.CompanyName || '-'}
                placement='top'
              >
                <ListItemText
                  primary={contractSummary?.CompanyName || '-'}
                  primaryTypographyProps={{
                    sx: {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    },
                  }}
                />
              </Tooltip>
            </ListItem>

            <ListItem sx={{ alignItems: 'baseline', gap: 4 }}>
              <ListItemIcon>
                <Typography fontWeight={500}>
                  <IntlMessages id='bundleContract.contract' />
                </Typography>
              </ListItemIcon>
              <Tooltip
                title={contractSummary?.ContractName || '-'}
                placement='top'
              >
                <ListItemText
                  primary={contractSummary?.ContractName || '-'}
                  primaryTypographyProps={{
                    sx: {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    },
                  }}
                />
              </Tooltip>
            </ListItem>

            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography fontWeight={500}>
                  <IntlMessages id='productForm.balance' />
                </Typography>
              </ListItemIcon>

              <ListItemText
                primary={contractSummary?.PointsBalance ?? 0}
                primaryTypographyProps={{
                  sx: {
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'primary.main',
                  },
                }}
              />
            </ListItem>

            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography fontWeight={500}>
                  <IntlMessages id='lockedPoints' />
                </Typography>
              </ListItemIcon>
              <ListItemText
                primary={contractSummary?.LockPoints ?? 0}
                primaryTypographyProps={{
                  sx: {
                    fontSize: '15px',
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'warning.main',
                  },
                }}
              />
            </ListItem>

            <Divider variant='middle' />

            {/* Total Purchased Points */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography fontWeight={500}>
                  <IntlMessages id='bundle.totalPurchasedPoints' />
                </Typography>
              </ListItemIcon>

              <ListItemText
                primary={totalPurchasedPoints ?? 0}
                primaryTypographyProps={{
                  sx: {
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'success.main',
                  },
                }}
              />
            </ListItem>

            {/* Total Prices */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography fontWeight={500}>
                  <IntlMessages id='bundleContract.totalPrices' />
                </Typography>
              </ListItemIcon>

              <ListItemText
                primary={numberToCurrency(totalPrices ?? 0, 2, 'GBP')}
                primaryTypographyProps={{
                  sx: {
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'primary.main',
                  },
                }}
              />
            </ListItem>

            {/* Discount */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography fontWeight={500}>
                  <IntlMessages id='bundle.totalDiscount' />
                </Typography>
              </ListItemIcon>

              <ListItemText
                primary={parseDebitAmount(totalDiscount ?? 0)}
                primaryTypographyProps={{
                  sx: {
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'primary.main',
                  },
                }}
              />
            </ListItem>

            <Divider variant='middle' />

            {/* Subtotal */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography fontWeight={500}>
                  <IntlMessages id='bundle.subTotal' />
                </Typography>
              </ListItemIcon>

              <ListItemText
                primary={numberToCurrency(discountedTotalPrices ?? 0, 2, 'GBP')}
                primaryTypographyProps={{
                  sx: {
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'primary.main',
                  },
                }}
              />
            </ListItem>

            {/* Tax */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography fontWeight={500}>
                  <IntlMessages
                    id='bundle.countryTax'
                    values={{
                      tax: (
                        <Typography
                          component={'span'}
                          variant='subtitle1'
                          fontStyle={'italic'}
                        >
                          {contractSummary?.TaxValue ?? 0}
                        </Typography>
                      ),
                    }}
                  />
                </Typography>
              </ListItemIcon>

              <ListItemText
                primary={parseCreditAmount(taxAmount) ?? 0}
                primaryTypographyProps={{
                  sx: {
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'error.main',
                  },
                }}
              />
            </ListItem>

            <Divider variant='middle' />

            {/* Total Payment */}
            <ListItem sx={{ alignItems: 'baseline' }}>
              <ListItemIcon>
                <Typography fontWeight={500}>
                  <IntlMessages id='bundle.total' />
                </Typography>
              </ListItemIcon>

              <ListItemText
                primary={numberToCurrency(totalPayment ?? 0, 2, 'GBP')}
                primaryTypographyProps={{
                  sx: {
                    textAlign: 'right',
                    fontWeight: 700,
                    color: 'primary.main',
                  },
                }}
              />
            </ListItem>
          </Stack>
        </Stack>
      </Grid>
    </AppGridContainer>
  );
};

export default StepConfirm;
