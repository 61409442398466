import React, { useState, useMemo, useRef, useCallback } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PercentIcon from '@mui/icons-material/Percent';
import PropTypes from 'prop-types';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 110,
    maxWidth: 110,
    maxHeight: 500,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '5px',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const validationSchema = yup.object({
  Percent: yup.number().required().integer().min(0).max(100),
});

const PercentMenu = ({
  defaultValue = 90,
  handleChangeValue,
  beforeText = '',
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuText, setMenuText] = useState(defaultValue);
  const open = Boolean(anchorEl);
  const initialValues = { Percent: 90 };
  const formRef = useRef(null);
  const [percent, setPercent] = useState(defaultValue);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box component={'div'} sx={{ display: 'inline' }}>
      <Button
        id='demo-customized-button'
        variant='standard'
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {beforeText} {menuText} <PercentIcon fontSize='small' />
      </Button>
      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Formik
          validateOnChange
          validateOnBlur
          initialValues={initialValues}
          validationSchema={validationSchema}
          innerRef={formRef}
          onSubmit={(data, { setSubmitting }) => {
            setMenuText(data.Percent);
            handleClose();
            handleChangeValue?.(data);
          }}
          enableReinitialize
        >
          {({ values, setFieldValue, errors }) => (
            <Form
              id='percentage-menu'
              style={{
                width: '100%',
              }}
              noValidate
              autoComplete='off'
            >
              <TextField
                type='number'
                fullWidth
                required
                variant='outlined'
                name='Percent'
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ min: 1, step: 1 }}
                placeholder='1-100'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>%</InputAdornment>
                  ),
                }}
                error={!!errors.Percent}
                value={percent}
                onChange={(e) => {
                  const value = e.target.value;

                  if (!value) {
                    setFieldValue('Percent', '');
                    setPercent('');
                    return;
                  }

                  if (value.match(/\./g)) {
                    const [, decimal] = value.split('.');

                    if (decimal?.length > 2) {
                      e.preventDefault();
                      return;
                    }

                    setFieldValue('Percent', Number(value));
                    setPercent(Number(value));
                    return;
                  }

                  setFieldValue('Percent', Number(e.target.value));
                  setPercent(Number(value));
                }}
              />
            </Form>
          )}
        </Formik>
      </StyledMenu>
    </Box>
  );
};

PercentMenu.propTypes = {
  defaultValue: PropTypes.number,
  handleChangeValue: PropTypes.func,
  beforeText: PropTypes.string,
};

export default React.memo(PercentMenu);
