import React from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePermittedRole } from 'shared/constants/AppConst';
import ContractDetail from './Company/components/ContractDetail';
import { MARKETPLACE_ROUTER_PATH } from './marketplace/constants';
import AllowedAccess from '@tenant/core/AppPermission/AllowedAccess';
import { AuthorizePermission } from '@tenant/utility/constants/authorizePermission';

const ToDo = React.lazy(() => import('./ToDo'));
const SMSAutomate = React.lazy(() => import('./SMSAutomate'));
const SubcontractorAutomate = React.lazy(() =>
  import('./SubcontractorAutomate'),
);

// Points System
const ProductUsage = React.lazy(() => import('./ProductUsage'));
const MultiProductForm = React.lazy(() =>
  import('./ProductUsage/components/FormPS'),
);
const RequestDetail = React.lazy(() =>
  import('./ProductUsage/components/RequestDetail'),
);
const RequestList = React.lazy(() =>
  import('./ProductUsage/components/RequestList'),
);
const EditRequest = React.lazy(() =>
  import('./ProductUsage/components/EditRequest'),
);
const BundleOrders = React.lazy(() => import('./BundleOrders'));

// Company
const Company = React.lazy(() => import('./Company'));
const CompanyList = React.lazy(() =>
  import('./Company/components/CompanyList'),
);
const CompanyAdd = React.lazy(() => import('./Company/components/CompanyAdd'));
const CompanyDetail = React.lazy(() =>
  import('./Company/components/CompanyDetail'),
);
const ContractEdit = React.lazy(() =>
  import('./Company/components/ContractEdit'),
);

// Migrate Tenant
const MigrateTenant = React.lazy(() => import('./MigrateTenant'));
const MigrateTenantAdd = React.lazy(() =>
  import('./MigrateTenant/components/migrateTenantAdd'),
);

//Marketplace
const AppsRequest = React.lazy(() => import('./marketplace/app-requests'));
const CreateSubscribeAppRequest = React.lazy(() =>
  import('./marketplace/create-subscribe-request'),
);
const CreateUnsubscribeAppRequest = React.lazy(() =>
  import('./marketplace/create-unsubscribe-request'),
);

export const appsConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: [
      '/apps/todo/label/:label',
      '/apps/todo/label/:label/:id',
      '/apps/todo/:folder',
      '/apps/todo/:folder/:id',
    ],
    element: <ToDo />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: [
      '/apps/smsautomate/:label',
      '/apps/smsautomate/:label/:id',
      '/apps/smsautomate/:folder',
      '/apps/smsautomate/:folder/:id',
    ],
    element: <SMSAutomate />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/apps/todo',
    element: <Navigate to='/apps/todo/all' />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/apps/publicapi',
    element: <Navigate to='/apps/publicapi/all' />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/apps/reportingRequest',
    element: <Navigate to='/apps/reportingRequest/all' />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/apps/smsautomate',
    element: <Navigate to='/apps/smsautomate/all' />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: '/apps/product-usage',
    element: <ProductUsage />,
    children: [
      {
        path: '/apps/product-usage/all',
        element: <RequestList />,
      },
      {
        path: '/apps/product-usage/all/:id',
        element: <RequestDetail />,
      },
      {
        path: '/apps/product-usage/all/:id/Edit',
        element: <EditRequest />,
      },
    ],
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: [
      '/apps/product-usage/add-request',
      '/apps/product-usage/:tenantId/add-request',
      '/apps/company/:companyId/tenant/:tenantId/add-request',
    ],
    element: <MultiProductForm />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: [
      '/apps/subcontractor-automate/all',
      '/apps/subcontractor-automate/add',
      '/apps/subcontractor-automate/all/:id',
    ],
    element: <SubcontractorAutomate />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: [
      '/apps/bundle-orders/all',
      '/apps/bundle-orders/all/:id',
      '/apps/bundle-orders/add',
      '/apps/company/:companyId/contract/:contractId/buy-points',
    ],
    element: <BundleOrders />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: '/company',
    element: <Company />,
    children: [
      {
        path: '/company',
        element: <CompanyList />,
      },
      {
        path: '/company/add',
        element: <CompanyAdd />,
      },
      {
        path: '/company/:id',
        element: <CompanyDetail />,
        type: 'Detail',
      },
      {
        path: '/company/:id/contract/:contract_id',
        element: <ContractDetail />,
        type: 'ContractDetail',
      },
      {
        path: '/company/:id/contract/:contract_id/edit',
        element: <ContractEdit />,
        type: 'ContractEdit',
      },
    ],
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: '/apps/migrate-tenant/all',
    element: <MigrateTenant />,
    children: [
      {
        path: '/apps/migrate-tenant/all/add',
        element: <MigrateTenantAdd />,
      },
    ],
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: '/apps/marketplace/request',
    element: <AppsRequest />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: MARKETPLACE_ROUTER_PATH.CREATE_SUBSCRIBE_REQUEST,
    element: <CreateSubscribeAppRequest />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: MARKETPLACE_ROUTER_PATH.CREATE_UNSUBSCRIBE_REQUEST,
    element: <CreateUnsubscribeAppRequest />,
  },
];
