import React, { useState, useEffect, useCallback, useMemo } from 'react';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import useModal from '@tenant/utility/hooks/useModal';
import styled from '@emotion/styled';
import IntlMessages from '@tenant/utility/IntlMessages';
import useContractService from '../../hooks/useContractService';
import useToast from '@tenant/utility/hooks/useToast';
import { deepClone } from '@tenant/utility/helper/Utils';
import { numberToInteger } from '@tenant/utility/helper/Utils';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import * as yup from 'yup';
import AppSearchInput from '@tenant/core/AppSearchBar/AppSearchInput';

const StyledStack = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.grey[100],
  marginTop: theme.spacing(4),
}));

const validationSchema = yup.object({
  Reason: yup.string().required(),
});

const LockPointsTable = ({
  PointsBalance,
  contractId,
  lockPointHistory,
  onCallbackAfterUnlockPoint,
  loading,
}) => {
  const { visible, onShow, onClose } = useModal();
  const { unlockPoint } = useContractService();
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useToast();
  const [selectedItem, setSelectedItem] = useState({});
  const [search, setSearch] = useState('');

  const columns = [
    {
      field: 'LockReason',
      headerName: 'Reason',
      width: 175,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography
            variant='body2'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'Points',
      headerName: 'Points',
      width: 100,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <Typography variant='body' fontWeight={600}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'RequestDate',
      headerName: 'Locked On',
      width: 150,
      renderCell: (params) =>
        params.value ? formatDateFromISO(params.value) : '',
    },
    {
      field: 'UnlockedAt',
      headerName: 'Unlocked On',
      width: 150,
      renderCell: (params) =>
        params.value ? formatDateFromISO(params.value) : '',
    },
    {
      field: 'RequestBy',
      headerName: 'Request By',
      width: 175,
    },
    {
      field: 'UnlockedBy',
      headerName: 'Unlocked By',
      width: 175,
    },
    {
      field: 'Unlock',
      headerName: '',
      width: 75,
      sortable: false,
      renderCell: (params) =>
        params.row?.CanUnlock && (
          <IconButton
            color='primary'
            title='Unlock Points'
            onClick={() => {
              handleShow(params.row);
            }}
            disabled={!params.row?.CanUnlock}
          >
            <KeyOffIcon />
          </IconButton>
        ),
    },
  ];

  const data = useMemo(
    () =>
      lockPointHistory?.filter(
        (history) =>
          history.LockReason?.toLowerCase().indexOf(search?.toLowerCase()) >= 0,
      ) ?? [],
    [lockPointHistory, search],
  );

  const handleShow = (params) => {
    setSelectedItem(deepClone(params));
    onShow();
  };

  const submitUnlockPoint = useCallback(
    (data) => {
      setIsLoading(true);
      unlockPoint({
        payload: {
          ContractId: contractId,
          LockedPointRequestId: data?.Id,
          UnlockReason: data?.Reason,
        },
        onSuccess: () => {
          showSuccessMessage('Unlock point successfully');
          onClose?.();
          onCallbackAfterUnlockPoint(contractId);
        },
        onError: (err) => {
          const message =
            typeof err?.response?.data?.Messages === 'string'
              ? err.response.data.Messages
              : 'Unlock point failed!';
          showErrorMessage(message);
        },
        onFinally: () => {
          setIsLoading(false);
        },
      });
    },
    [unlockPoint],
  );

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const clearSearchTerm = () => {
    setSearch('');
  };

  return (
    <>
      <Box
        mb={2}
        flex={1}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <AppSearchInput
          isClearable
          onClearSearch={clearSearchTerm}
          iconPosition='right'
          id='outlined-adornment-weight'
          size='small'
          searchValue={search ?? ''}
          overlap={false}
          onChange={onChangeSearch}
        />
      </Box>

      <DataGrid
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        sortingOrder={['desc', 'asc']}
        loading={isLoading || loading}
        rows={data}
        columns={columns}
        getRowId={(row) => row.Id}
        sx={{
          '& .MuiDataGrid-selectedRowCount': { opacity: 0 },
        }}
      />

      <Dialog open={visible} onClose={onClose} maxWidth='md' fullWidth>
        <DialogTitle fontSize={16}>Unlock Points</DialogTitle>
        <Formik
          initialValues={{
            Id: selectedItem.Id,
            Reason: '',
            Points: selectedItem.Points ?? 0,
            RemainingPoints: +PointsBalance + +(selectedItem.Points ?? 0),
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            submitUnlockPoint(values);
          }}
        >
          {({ submitForm, errors, values, setFieldValue }) => (
            <>
              <DialogContent>
                <Alert mb={3} severity='info'>
                  <Typography component={'i'}>
                    Once submitted, this points{' '}
                    <Typography component={'span'} color={'success.main'}>
                      will be activated and available
                    </Typography>{' '}
                    for use. Please confirm that you wish to proceed.
                  </Typography>
                </Alert>

                <Box>
                  <StyledStack direction='column'>
                    <ListItem
                      secondaryAction={
                        <Typography>{selectedItem?.RequestBy}</Typography>
                      }
                    >
                      <ListItemText primary={'Locked By'} />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography>
                          {!!selectedItem?.RequestDate &&
                            formatDateFromISO(selectedItem?.RequestDate)}
                        </Typography>
                      }
                    >
                      <ListItemText primary={'Locked On'} />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Tooltip title={selectedItem?.LockReason}>
                          <Typography
                            sx={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              maxWidth: 500,
                              minWidth: 100,
                              width: 'calc(100vw - 350px)',
                              textAlign: 'right',
                            }}
                          >
                            {selectedItem?.LockReason}
                          </Typography>
                        </Tooltip>
                      }
                    >
                      <ListItemText primary={'Locked Reason'} />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <Typography
                          sx={{
                            color: 'primary.main',
                          }}
                        >
                          {PointsBalance}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={<IntlMessages id='productForm.balance' />}
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography
                          sx={{
                            color: 'warning.main',
                          }}
                        >
                          {values.Points}
                        </Typography>
                      }
                    >
                      <ListItemText primary={'Unlock Points'} />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography
                          sx={{
                            color: 'success.main',
                            fontWeight: 600,
                          }}
                        >
                          {values.RemainingPoints}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={<IntlMessages id='newPoints' />}
                        primaryTypographyProps={{
                          fontWeight: 500,
                        }}
                      />
                    </ListItem>
                  </StyledStack>
                </Box>

                <Box mt={4}>
                  <TextField
                    fullWidth
                    required
                    multiline
                    rows={4}
                    value={values.Reason ?? ''}
                    label='Reason'
                    variant='outlined'
                    error={!!errors.Reason}
                    helperText={errors.Reason}
                    onChange={(e) => setFieldValue('Reason', e.target.value)}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <LoadingButton
                  onClick={submitForm}
                  variant='contained'
                  autoFocus
                  loading={isLoading}
                >
                  Unlock
                </LoadingButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default React.memo(LockPointsTable);

LockPointsTable.propTypes = {
  PointsBalance: PropTypes.number,
  contractId: PropTypes.string,
  lockPointHistory: PropTypes.array,
  onCallbackAfterUnlockPoint: PropTypes.func,
  loading: PropTypes.bool,
};
