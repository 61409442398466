import { parseCredit, parseDebit } from 'pages/apps/ProductUsage/helpers';

export const API = {
  GET_REACH_CAP_STATISTIC:
    '/api/JLPointDataAnalyzer/Dashboard/GetTenantsReachingCap',
  GET_REACH_EXPIRY_STATISTIC:
    '/api/JLPointDataAnalyzer/Dashboard/GetTenantsReachingExpiry',
  GET_TENANT_PURCHASE_DISTRIBUTION:
    '/api/JLPointDataAnalyzer/Dashboard/GetTenantsPurchaseDistribution',
  GET_COMPANY_PURCHASE_DISTRIBUTION:
    '/api/JLPointDataAnalyzer/Dashboard/GetCompanyPurchaseDistribution',
  GET_RENEWAL_EVENTS: '/api/Contract/SearchRenewalEvents',
  GET_TRANSACTION_STATISTIC: 'api/JLPointDataAnalyzer/GetTransactionsStatistic',
  GET_TRANSACTION: 'api/JLPointDataAnalyzer/GetTransactions',
};

export const RENEWAL_DASHBOARD_COLUMNS = [
  {
    field: 'CompanyName',
    headerName: 'Company Name',
    style: {
      width: 200,
      maxWidth: 250,
      backgroundColor: '#FFFFFF',
    },
  },
  {
    field: 'ContractName',
    headerName: 'Contract Name',
    style: {
      width: 175,
      backgroundColor: '#FFFFFF',
    },
  },
  {
    field: 'ContractLength',
    headerName: 'Contract Length',
    hiddenSort: true,
    style: {
      width: 150,
      backgroundColor: '#FFFFFF',
    },
  },
  {
    field: 'SubscriptionEnd',
    headerName: 'Subscription End',
    style: {
      width: 175,
      backgroundColor: '#FFFFFF',
    },
  },
  {
    field: 'EventType',
    headerName: 'Event Type',
    style: {
      width: 150,
      backgroundColor: '#FFFFFF',
    },
  },
  {
    field: 'EventDate',
    headerName: 'Event Date',
    style: {
      width: 150,
      backgroundColor: '#FFFFFF',
    },
  },
];

export const ROW_PER_PAGE = 10;

export const EVENT_TYPE_ENUMS = {
  GENERATE_DRAFT: 'Generate Draft',
  SEND_ESTIMATED_QUOTE: 'Send Estimated Quote',
  SEND_PAYMENT_COLLECTION: 'Send Payment Collection',
  ACTIVE_DRAFT_CONTRACT: 'Active Draft Contract',
};

export const EVENT_TYPE_DESCRIPTION_CONVERT = {
  GenerateDraft: 'Generate Draft',
  SendEstimatedQuote: 'Send Estimated Quote',
  SendPaymentCollection: 'Send Payment Collection',
  ActiveDraftContract: 'Active Draft Contract',
};

export const CONTRACT_LENGTH_ENUMS = {
  '1 Year': 2,
  '2 Years': 3,
  '3 Years': 4,
  '5 Years': 5,
};

export const TRANSACTION_TYPE_MODEL = {
  Burned: {
    label: 'Burned',
    color: 'error.main',
    isShowTenant: true,
    isShowAmount: false,
    parseValue: (value) => parseDebit(value),
  },
  Refunded: {
    label: 'Refunded',
    color: 'success.main',
    isShowTenant: true,
    isShowAmount: false,
    parseValue: (value) => parseCredit(value),
  },
  Purchased: {
    label: 'Purchased',
    color: 'success.main',
    isShowTenant: false,
    isShowAmount: true,
    parseValue: (value) => parseCredit(value),
  },
  Revoked: {
    label: 'Revoked',
    color: 'error.main',
    isShowTenant: false,
    isShowAmount: true,
    parseValue: (value) => parseDebit(value),
  },
  Expired: {
    label: 'Expired',
    color: 'error.main',
    isShowTenant: false,
    isShowAmount: true,
    parseValue: (value) => parseDebit(value),
  },
};

export const POINT_TRANSACTION_TYPE_FILTER_ENUM = {
  Burned: 0,
  Refunded: 1,
  Purchased: 2,
  Revoked: 3,
  Expired: 4,
};
