import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_AUTHORIZATION_FIELD,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@tenant/utility/IntlMessages';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import { deepClone } from '@tenant/utility/helper/Utils';

export const getUserInfo = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('/api/User/GetUserInfo')
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SET_AUTHORIZATION_FIELD,
            payload: {
              user: data.data,
              loading: true,
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getUserFeatures = () => {
  return async (dispatch) => {
    try {
      const mainFeatureQuery = jwtAxios.get(
        '/api/FeatureUsage/FilterMainFeatures?havingUsage=true',
      );
      const allEndpointsQuery = jwtAxios.get(
        '/api/FeatureUsage/FilterEndpoints',
      );

      const [mainFeatureResponse, allEndpointsResponse] = await Promise.all([
        mainFeatureQuery,
        allEndpointsQuery,
      ]);

      dispatch({
        type: SET_AUTHORIZATION_FIELD,
        payload: {
          mainFeatures: deepClone(mainFeatureResponse?.data),
          endpoints: deepClone(allEndpointsResponse?.data),
          loadingFeature: true,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};
