import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import TeltonikaList from '../VehicleTracking/TeltonikaList';
import LazyLoad from 'react-lazy-load';
import AppCard from '@tenant/core/AppCard';
import { deepClone, formatNumber } from '@tenant/utility/helper/Utils';
import AppGlossary from '@tenant/core/App/Common/AppGlossary';

function getRandomArbitrary(min, max) {
  return parseFloat(Math.random() * (max - min) + min).toFixed(0);
}

const VehicleTrackingTab = ({ tenantId }) => {
  const [vehicleTrackingRecords, setVehicleTrackingRecords] = useState([]);
  const [vehicleTrackingDetail, setVehicleTrackingDetail] = useState();
  const exampleTotal = useMemo(
    () =>
      getRandomArbitrary(
        vehicleTrackingRecords?.length ?? 0,
        (vehicleTrackingRecords?.length ?? 0) * 2,
      ),
    [vehicleTrackingRecords?.length],
  );

  const totalActiveDevices = useMemo(
    () =>
      (vehicleTrackingRecords?.length ?? 0) -
      (vehicleTrackingRecords?.filter((e) => e.ActivityStatus === 'Inactive')
        ?.length ?? 0),

    [vehicleTrackingRecords],
  );

  const onSetVehicleTrackingRecords = useCallback((data) => {
    setVehicleTrackingDetail(data);
    setVehicleTrackingRecords(deepClone(data?.Items));
  }, []);

  return (
    <LazyLoad offset={100}>
      <AppCard
        title='Vehicle Tracking'
        sxStyle={{
          height: '100%',
          '& .MuiListItemText-secondary': {
            fontSize: 16,
          },
          '& .MuiListItemText-primary': {
            fontWeight: 400,
          },
        }}
      >
        <Grid container spacing={4} mb={5}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <ListItemText
                      primary={
                        <Box display={'flex'} alignItems={'center'}>
                          Total Vehicle{' '}
                          <AppGlossary
                            title={
                              'A library of all vehicles that can be used as mobile stock locations.'
                            }
                          />
                        </Box>
                      }
                      secondary={formatNumber(
                        vehicleTrackingDetail?.TotalVehicles ?? 0,
                      )}
                      secondaryTypographyProps={{ color: 'primary.main' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ListItemText
                      primary={
                        <Box display={'flex'} alignItems={'center'}>
                          Total UnMap
                          <AppGlossary
                            title={
                              'Difference between Total Vehicles and Total Bought Devices.'
                            }
                          />
                        </Box>
                      }
                      secondary={formatNumber(
                        (vehicleTrackingDetail?.TotalVehicles ?? 0) -
                          (vehicleTrackingDetail?.TotalDevices ?? 0),
                      )}
                      secondaryTypographyProps={{ color: 'error.main' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ListItemText
                      primary={
                        <Box display={'flex'} alignItems={'center'}>
                          Total Bought Device{' '}
                          <AppGlossary
                            title={`Sum of total Active devices (${formatNumber(
                              totalActiveDevices,
                            )}) and InActive devices (${formatNumber(
                              vehicleTrackingDetail?.TotalDevices -
                                totalActiveDevices ?? 0,
                            )})`}
                          />
                        </Box>
                      }
                      secondary={formatNumber(
                        vehicleTrackingDetail?.TotalDevices ?? 0,
                      )}
                      secondaryTypographyProps={{ color: 'success.main' }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <TeltonikaList
          tenantId={tenantId}
          height={350}
          onSetVehicleTrackingRecords={onSetVehicleTrackingRecords}
        />
      </AppCard>
    </LazyLoad>
  );
};

export default React.memo(VehicleTrackingTab);

VehicleTrackingTab.propTypes = {};
