export const PAYMENT_STATUS = {
  0: {
    Name: 'Inactive',
    Color: '#FF9900',
    Id: 0,
  },
  1: {
    Name: 'Active',
    Color: '#339900',
    Id: 1,
  },
  2: {
    Name: 'Draft',
    Color: '#19B5FE',
    Id: 2,
  },
  3: {
    Name: 'Pending Cancel',
    Color: '#d32f2f',
    Id: 3,
  },
  4: {
    Name: 'Locked Out',
    Color: '#e53935',
    Id: 4,
  },
};

export const PAYMENT_STATUS_ARRAY = Object.entries(PAYMENT_STATUS).map(
  ([key, value]) => {
    return {
      Id: key,
      Name: value.Name,
    };
  },
);

export const PAYMENT_STATUS_ENUM = {
  Inactive: 0,
  Active: 1,
  Draft: 2,
  'Pending Cancel': 3,
  'Locked Out': 4,
};

export const CONTRACT_TYPE = [
  { Id: '1', Description: 'Single' },
  // { Id: '2', Description: 'Multiple' },
];

export const PAYMENT_METHODS_ENUM = [
  // {
  //   Id: '0',
  //   Description: 'None',
  // },
  {
    Id: '1',
    Description: 'Stripe',
  },
  {
    Id: '2',
    Description: 'GoCardless',
  },
  // {
  //   Id: '3',
  //   Description: 'Online',
  // },
];

export const PAYMENT_METHODS_ENUM_OBJ = {
  Stripe: '1',
  GoCardless: '2',
};

export const PAYMENT_METHODS_ENUM_OBJECT = PAYMENT_METHODS_ENUM.reduce(
  (obj, item) =>
    Object.assign(obj, { [Number.parseInt(item.Id)]: item?.Description }),
  {},
);
export const STRIPE_NUMBER = '1';

export const CONTRACT_LENGTH_MONTH_NUMBER = '1';
export const CONTRACT_LENGTH_CUSTOM_NUMBER = '4';
export const CONTRACT_LENGTH_1_YEAR = '2';
export const CONTRACT_LENGTH_2_YEAR = '3';
export const CONTRACT_LENGTH_3_YEARS = '4';
export const CONTRACT_LENGTH_5_YEARS = '5';

export const CONTRACT_LENGTH_ENUM = [
  { Id: '1', Description: 'Monthly', Number: 1, Frequency: 'Monthly' },
  { Id: '2', Description: '1 Year', Number: 12, Frequency: '1 Year' },
  { Id: '3', Description: '2 Years', Number: 24, Frequency: '2 Year' },
  { Id: '4', Description: '3 Years', Number: 36, Frequency: '3 Years' },
  { Id: '5', Description: '5 Years', Number: 60, Frequency: '5 Years' },
  // { Id: '4', Description: 'Custom' },
];

export const CONTRACT_LENGTH_BY_PAYMENT_ENUM = CONTRACT_LENGTH_ENUM.reduce(
  (obj, item) =>
    Object.assign(obj, { [Number.parseInt(item.Id)]: item?.Description }),
  {},
);

export const PACKAGE_TYPE_ENUM = [
  { Id: '1', Description: 'Basic', ContractLength: 1 },
  { Id: '2', Description: 'Premium', ContractLength: 1 },

  { Id: '1', Description: 'Basic', ContractLength: 2 },
  { Id: '2', Description: 'Premium', ContractLength: 2 },
  { Id: '3', Description: 'Enterprise', ContractLength: 2 },
  { Id: '4', Description: 'Enterprise Plus', ContractLength: 2 },

  { Id: '1', Description: 'Basic', ContractLength: 3 },
  { Id: '2', Description: 'Premium', ContractLength: 3 },
  { Id: '3', Description: 'Enterprise', ContractLength: 3 },
  { Id: '4', Description: 'Enterprise Plus', ContractLength: 3 },

  { Id: '1', Description: 'Basic', ContractLength: 4 },
  { Id: '2', Description: 'Premium', ContractLength: 4 },
  { Id: '3', Description: 'Enterprise', ContractLength: 4 },
  { Id: '4', Description: 'Enterprise Plus', ContractLength: 4 },

  { Id: '1', Description: 'Basic', ContractLength: 5 },
  { Id: '2', Description: 'Premium', ContractLength: 5 },
  { Id: '3', Description: 'Enterprise', ContractLength: 5 },
  { Id: '4', Description: 'Enterprise Plus', ContractLength: 5 },
];

export const PACKAGE_TYPE_ENUM_OBJECT = PACKAGE_TYPE_ENUM.reduce(
  (obj, item) => Object.assign(obj, { [item.Id]: item?.Description }),
  {},
);

export const PACKAGE_TYPE_BY_CONTRACT_ENUM = (value) => {
  if (value) {
    return PACKAGE_TYPE_ENUM.filter((item) => {
      return item.ContractLength === parseInt(value);
    });
  }
  return PACKAGE_TYPE_ENUM.filter((item) => {
    return item.ContractLength === 2;
  });
};

export const PAYMENT_TERMS_ENUM = [
  { Id: '0', Description: '100% up-front' },
  {
    Id: '1',
    Description:
      '20% up-front and remaining over 10 monthly \n' +
      'payments using GoCardless debiting system.',
  },
  { Id: '2', Description: 'Leasing offered, 1+59 Payment Profile.' },
];

export const TAX_RATE_ENUM = [
  { Id: '0', Description: '0%' },
  { Id: '20', Description: '20%' },
];

export const NUMBER_TAB_CREATE_CONTRACT = 0;
export const NUMBER_TAB_SELECT_TENANT = 0;

export const PROMO_CODE = [
  { Id: '1', Description: '1 month', Number: 1 },
  { Id: '2', Description: '2 months', Number: 2 },
  { Id: '3', Description: '3 months', Number: 3 },
];

export const AUDIT_TYPE_ENUM = {
  0: 'None',
  1: 'Add Users',
  2: 'Remove User',
};

export const AUDIT_STATUS_ENUM = {
  pending: 'Pending',
};

export const PROMO_CODE_OBJ = (value) => {
  if (value) {
    const result = PROMO_CODE.find((item) => {
      return parseInt(item.Id) === parseInt(value);
    });
    return result ?? null;
  }
  return null;
};

export const COMPANY_STATUS = {
  1: 'Active',
  2: 'InActive',
};

export const COMPANY_STATUS_TEXT = {
  Active: 1,
  InActive: 2,
};

export const TYPE_OF_CONFIRM = {
  RENEWAL: 'RENEWAL',
  CANCEL: 'CANCEL',
};

export const TYPE_OF_CANCEL = {
  IMMEDIATE: 1,
  PENDING: 2,
};

export const REASON_CANCEL = {
  1: 'Cost',
  2: 'Functionality',
  3: 'Company disbanding',
  4: 'Implementation',
  5: 'No reason provided',
  6: 'Other',
};

export const SUBSCRIPTION_STATUS = {
  Active: 'active',
  Canceled: 'canceled',
  'Pending Cancel': 'pending cancel',
  'Pending Activation': 'pending activation',
  Trialing: 'trialing',
  'Locked Out': 'locked out',
};

export const INVOICE_TYPE_ENUM = {
  'Initial Contract': 1,
  'Contract Additional': 2,
  'Bundle Invoice': 3,
  'Credit Invoice': 4,
  'Vehicle Tracking': 5,
  Subcontractor: 6,
};

export const INVOICE_TYPE_VALUE = Object.fromEntries(
  Object.entries(INVOICE_TYPE_ENUM).map((a) => a.reverse()),
);

export const CONTRACT_TYPE_ENUM = {
  SINGLE: 1,
  MULTIPLE: 2,
};

export const INVOICE_STATUS = {
  1: {
    Name: 'Draft',
    Color: '#19B5FE',
    Id: 1,
  },
  2: {
    Name: 'Submitted',
    Color: '#ff9900',
    Id: 2,
  },
  3: {
    Name: 'Deleted',
    Color: '#e53935',
    Id: 3,
  },
  4: {
    Name: 'Authorised',
    Color: '#0d47a1',
    Id: 4,
  },
  5: {
    Name: 'Paid',
    Color: '#339900',
    Id: 5,
  },
  6: {
    Name: 'Voided',
    Color: '#9e9e9e',
    Id: 6,
  },
};

export const INCREASE_MAX_USERS_STEPS = [
  {
    step: 1,
    label: 'Charge through Stripe',
  },
  {
    step: 0,
    label: 'Issue Invoice',
  },
  {
    step: 2,
    label: 'Increase number of user',
  },
];

export const DEFAULT_VALUE_JSON = 'object';

export const FREQUENCY_VALUE = {
  ONE_YEAR: '1 year',
  TWO_YEAR: '2 year',
  THREE_YEAR: '3 years',
  FIVE_YEAR: '5 years',
};

export const CONTRACT_STATUS_ENUM = {
  Inactive: 0,
  Active: 1,
  Draft: 2,
  PendingCancel: 3,
};

export const STEP_CREATE_CONTRACT = {
  SELECT_TENANT: 0,
  CONTRACT_CONFIGURATION: 1,
  CONFIRM_FORM: 2,
};

export const COMPANY_TABS_ENUM = {
  DETAIL: 0,
  MARKETPLACE: 1,
};

export const COMPANY_TABS_LIST = [
  {
    id: 'company-detail',
    title: 'Detail',
  },
  {
    id: 'company-marketplace',
    title: 'Marketplace',
  },
];
