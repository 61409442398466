export const makeColTotalStyle = ({ loading }) => ({
  '& .col-total': {
    borderRight: '1px dashed #ddd',
    textAlign: 'center',
    p: 4,
    cursor: 'pointer',
    transition: '0.5s',
    pointerEvents: loading && 'none',

    '&:hover': {
      backgroundColor: '#fafafa',
    },

    '&.non-action:hover': {
      backgroundColor: 'inherit',
      cursor: 'inherit',
    },

    '&.non-action .col-title': {
      color: 'inherit',
    },
  },
  '& .col-total:last-child': {
    borderRight: '0',
  },
  '& .col-title': {
    color: '#637381',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.57143,
    mb: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiSvgIcon-root': {
      mb: 1,
    },
  },
  '& .active': {
    backgroundColor: '#fff',
    borderRadius: 1,
  },
});
