import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Label,
} from 'recharts';
import { formatNumber, sortByField } from '@tenant/utility/helper/Utils';
import { List } from 'react-content-loader';
import { Box } from '@mui/material';
import Bullet from '@tenant/core/App/Chart/PieChart/components/Bullet';

const COLORS = [
  '#fa6a64',
  '#5a2476',
  '#755dff',
  '#373715',
  '#3d4381',
  '#91b776',
  '#356f61',
  '#a19b66',
  '#ff5a36',
  '#f1c232',
  '#2b2b2b',
  '#b5b3f4',
  '#bd33a4',
  '#e4717a',
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#333'
      >{`${props?.name} ${formatNumber(value)}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill='#999'
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const CustomizedLegend = (props) => {
  const { payload } = props;
  return payload.map((entry, index) => (
    <Box
      key={entry.payload.Key}
      component={'span'}
      p={2}
      display={'inline-block'}
    >
      <Box
        display={'inline-block'}
        component={'span'}
        className='CirecleBullet'
        style={{
          backgroundColor: entry.payload.fill,
          width: 10,
          height: 10,
        }}
      ></Box>
      <Box component={'span'} className='BulletLabelText'>
        {entry.payload.Key} ({formatNumber(entry.payload.Total)})
      </Box>
    </Box>
  ));
};

const CustomizedLabel = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy - 15}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fontSize='12'
      >
        {labelText}
      </text>
      <text
        x={cx}
        y={cy + 15}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fill='#82ca9d'
        fontSize='26'
        fontWeight='600'
      >
        {value}
      </text>
    </g>
  );
};

const BundleUsagePerBundleTypeChart = ({ data, loading }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const convertData = useMemo(
    () =>
      data?.map((e) => ({ Key: e.BundleName, Total: e.TotalPointsPurchased })),
    [data],
  );

  return loading ? (
    <List />
  ) : convertData?.length > 0 ? (
    <ResponsiveContainer width='100%' height='100%'>
      <PieChart width={400} height={400}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={convertData}
          cx='50%'
          cy='50%'
          innerRadius={60}
          outerRadius={80}
          dataKey='Total'
          nameKey='Key'
          onMouseEnter={(payload, index) => {
            setActiveIndex(index);
          }}
        >
          {convertData?.map((entry, index) => (
            <Cell key={entry.Key} fill={COLORS[index % COLORS.length]} />
          ))}

          <Label
            content={
              <CustomizedLabel
                labelText='Total Points Sold'
                value={formatNumber(
                  convertData?.reduce((a, b) => a + (b?.Total ?? 0), 0),
                )}
              />
            }
            position='center'
          />
        </Pie>
        <Legend
          formatter={(value, entry, index) =>
            `${value} (${entry?.payload?.Total})`
          }
          content={<CustomizedLegend />}
        />
      </PieChart>
    </ResponsiveContainer>
  ) : (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ height: 400 }}
    >
      Empty
    </Box>
  );
};

export default React.memo(BundleUsagePerBundleTypeChart);

BundleUsagePerBundleTypeChart.propTypes = {};
