import React from 'react';
import PropTypes from 'prop-types';
import { Box, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const AppTableSortLabel = ({
  handleSortColumn,
  orderByField,
  orderByDirection,
  columnLabel,
  columnName,
}) => {
  return (
    <TableSortLabel
      component={'div'}
      display='flex'
      sx={{ width: '100%' }}
      active={orderByField === columnName}
      direction={orderByField === columnName ? orderByDirection : 'asc'}
      onClick={() =>
        handleSortColumn({
          field: columnName,
          direction: orderByField === columnName ? orderByDirection : '',
        })
      }
    >
      {columnLabel}
      {orderByField === columnName
        ? !!orderByDirection && (
            <Box component='span' sx={visuallyHidden}>
              {orderByDirection === 'desc'
                ? 'sorted descending'
                : 'sorted ascending'}
            </Box>
          )
        : null}
    </TableSortLabel>
  );
};

export default React.memo(AppTableSortLabel);

AppTableSortLabel.propTypes = {
  orderByField: PropTypes.string.isRequired,
  orderByDirection: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  columnLabel: PropTypes.node.isRequired,
  handleSortColumn: PropTypes.func,
};
