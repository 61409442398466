import React, { useState, useCallback, useRef, useEffect, } from 'react';
import PropTypes from 'prop-types';
import { Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TuneIcon from '@mui/icons-material/Tune';

const isEmptyFilters = (filters) => {
  return Object.values(filters)
    .map(value => {
      if (Array.isArray(value) && !value.length) return null;
      return value;
    })
    .every(value => !value);
};

const FilterFormContainer = ({
  handleSave,
  handleReset,
  renderContent,
}) => {
  const [open, setOpen] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const { filterForm } = useSelector(({ tenantList }) => tenantList);
  const form = useRef(filterForm);

  const handleChangeFilters = useCallback((formFilters) => {
    setIsApplied(false);
    form.current = formFilters;
  }, []);

  const handleCollapseIn = useCallback(() => {
    setIsApplied(!isEmptyFilters(filterForm));
    setOpen(!open);
  }, [filterForm, open]);

  useEffect(() => {
    form.current = filterForm;
  }, [filterForm]);

  return (
    <Paper elevation={1} sx={{ p: '12px 16px', mt: 3, borderRadius: 3 }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          if (open) {
            setOpen(!open);
            return;
          }
          handleCollapseIn();
        }}
      >
        <Box component={'label'} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TuneIcon fontSize='small' color='primary' />
          <Typography variant='h5' color={'primary.main'}>
            Filters
          </Typography>
        </Box>

        {open ? (
          <IconButton onClick={() => setOpen(!open)} size='small' sx={{ p: 0, color: 'primary.main' }}>
            <ExpandLess />
          </IconButton>
        ) : (
          <IconButton onClick={handleCollapseIn} size='small' sx={{ p: 0, color: 'primary.main' }}>
            <ExpandMore />
          </IconButton>
        )}
      </Stack>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider sx={{ mt: 2, mb: 3 }} />
        <Grid container spacing={4}>
          {renderContent?.({ isApplied, filterForm, handleChangeFilters })}
        </Grid>

        <Box
          component={'div'}
          sx={{
            textAlign: 'right',
            mt: 3
          }}>
          {isApplied && (
            <Typography component={'span'} variant='body2'>
              Filters are applied
            </Typography>
          )}

          <Button
            size="small"
            color='secondary'
            variant='outlined'
            sx={{ mr: 2, ml: 2 }}
            onClick={() => {
              form.current = {};
              setOpen(false);
              !isEmptyFilters(filterForm) && handleReset?.();
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              if (isEmptyFilters(form.current) && isEmptyFilters(filterForm)) return;
              handleSave?.(form.current);
              setIsApplied(true);
            }}
          >
            Save
          </Button>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default React.memo(FilterFormContainer);

FilterFormContainer.propTypes = {
  handleSave: PropTypes.func,
  handleReset: PropTypes.func,
  renderContent: PropTypes.func,
};
