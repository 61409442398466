import React, { useEffect, useState, useCallback, useMemo } from 'react';
import AppContentView from '@tenant/core/AppContentView';
import { useAuthUser } from '../../utility/AuthHooks';
import {
  useLayoutActionsContext,
  useLayoutContext,
} from '../../utility/AppContextProvider/LayoutContextProvider';
import AuthWrapper from './AuthWrapper';
import { useUrlSearchParams } from 'use-url-search-params';
import { useSidebarActionsContext } from '../../utility/AppContextProvider/SidebarContextProvider';
import Layouts from './Layouts';
import { useDispatch, useSelector } from 'react-redux';
import { useADActions } from '@tenant/services/auth/firebase/FirebaseAuthProvider';
import { useSnackbar } from 'notistack';
import { getTotalCountNotification } from 'redux/actions/Notification';
import { SET_FIELD_NOTIFICATION } from 'shared/constants/ActionTypes';
import useConnectionNotification from '@tenant/utility/hooks/useConnectionNotification';
import NotifyToastTemplate from '../AppNotifications/NotifyToastTemplate';
import { audioNotificationBase64 } from './defaultNotification';
import { getUserFeatures, getUserInfo } from 'redux/actions';
import { AuthorizePermission } from '@tenant/utility/constants/authorizePermission';

const AppLayout = () => {
  const { user } = useSelector((s) => s.authorization);
  const dispatch = useDispatch();
  const { totalCount, notifications, receiveNotifications } = useSelector(
    (s) => s.notification,
  );
  const { navStyle } = useLayoutContext();
  const { logout } = useADActions();
  const { isAuthenticated, isPointPermission } = useAuthUser();
  const { updateNavStyle } = useLayoutActionsContext();
  const { updateMenuStyle, setSidebarBgImage } = useSidebarActionsContext();
  const AppLayoutComponent = Layouts[navStyle];
  const [params] = useUrlSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const accessToken = localStorage.getItem('token');
  const connection = useConnectionNotification({
    url: process.env.REACT_APP_HOST + 'api/signalr',
    accessToken,
  });
  const [newNotification, setNewNotification] = useState();
  const { Permissions } = user || {};
  const isCanViewAnalytics = useMemo(
    () =>
      Permissions?.some(
        (e) => e.PermissionId === AuthorizePermission['Analytic - View'],
      ),
    [Permissions],
  );

  const makeSound = useCallback(async () => {
    const audio = new Audio(audioNotificationBase64);
    try {
      audio.setAttribute(
        'allow',
        'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
      );
      await audio.play();
    } catch (err) {
      console.error('Failed to play, error: ' + err);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserInfo());
      dispatch(getTotalCountNotification());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (newNotification) {
      dispatch({
        type: SET_FIELD_NOTIFICATION,
        payload: {
          totalCount: totalCount + 1,
          notifications:
            notifications?.length > 0
              ? [newNotification, ...notifications]
              : [newNotification],
          receiveNotifications:
            receiveNotifications?.length > 0
              ? [newNotification, ...receiveNotifications]
              : [newNotification],
        },
      });
    }
  }, [dispatch, newNotification]);

  useEffect(() => {
    const process = async () => {
      if (connection) {
        await connection.start().catch((err) => console.error(err));
        await connection.on('addClientNotification', async (message) => {
          setNewNotification(message);
          await makeSound();
          enqueueSnackbar(message?.content, {
            // persist: true,
            content: (key) => <NotifyToastTemplate id={key} item={message} />,
          });
        });
      }
    };

    process();

    return async () => {
      await connection?.stop();
    };
  }, [connection]);

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout);
    if (params.menuStyle) updateMenuStyle(params.menuStyle);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, [
    params.layout,
    params.menuStyle,
    params.sidebarImage,
    updateNavStyle,
    updateMenuStyle,
    setSidebarBgImage,
  ]);

  useEffect(() => {
    if (isAuthenticated && !isPointPermission) {
      logout();
      return;
    }
  }, [isAuthenticated, isPointPermission, dispatch]);

  useEffect(() => {
    if (isCanViewAnalytics) {
      dispatch(getUserFeatures());
    }
  }, [isCanViewAnalytics]);

  return (
    <>
      {isAuthenticated ? (
        <AppLayoutComponent />
      ) : (
        <AuthWrapper>
          <AppContentView />
        </AuthWrapper>
      )}
    </>
  );
};

export default React.memo(AppLayout);
