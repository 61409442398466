export const API_ENDPOINT = {
  GET_LIST_COMPANY: '/api/Contract/GetCompanies',
  GET_TENANT: '/api/Contract/GetTenants',
  GET_INIT_DATA: '/api/Contract/GetInitialData',
  ADD_COMPANY: '/api/Contract/AddContract',
  GET_CONTRACTS: '/api/Contract/GetContracts',
  GET_TENANT_DETAIL_CONTRACT: '/api/Contract/GetTenantDetail',
  GET_TENANT_DETAIL: '/api/Tenant',
  GET_TENANT_LIST: '/api/Tenant/GetAll',
  GET_CONTRACT_ITEMS: '/api/Contract/GetContractItems',
  GET_CONTRACT_DETAIL: '/api/Contract/GetContractDetail',
  GET_CONTRACT_BY_TENANT: 'api/Contract/GetContractByTenant',
  GET_LIST_PAYMENT_TERM: '/api/Contract/GetPaymentTerms',
  GET_TENANT_BY_COMPANY: '/api/Contract/GetTenantByCompany',
  GET_INVOICES: '/api/Contract/GetInvoices',
  CHECK_EXISTING_TENANT: '/api/Contract/CheckExistingContract',
  EDIT_CONTRACT: '/api/Contract/EditContractConfigurations',
  GET_CONTRACT_ID: '/api/Contract/GetContractId',
  ADD_TENANT_TO_COMPANY: '/api/Contract/AddTenantToCompany',
  REMOVE_TENANT_FROM_COMPANY: '/api/Contract/RemoveTenantFromCompany',
  CHANGE_PRIMARY_TENANT: '/api/Contract/ChangePrimaryTenant',
  GET_LIST_COMPANY_FILTER: '/api/Contract/FilterCompanies',
  GET_TRACER_USER: '/api/Contract/GetTrackerUsers',
  GET_TRACER_TEAM: '/api/Contract/GetTrackerUserTeams',
  UPDATE_TENANT_PRECONDITION: '/api/Contract/UpdatePrecondition',
  DOWNLOAD_ATTACHMENT: '/api/Contract/DownloadAttachment',
  GET_COMPANY: '/api/Contract/GetCompany',
  UPDATE_COMPANY: '/api/Contract/UpdateCompany',
  CANCEL_RENEWAL: '/api/Contract/CancelRenewal',
  EXTEND_TRIAL: '/api/Tenant/ExtendTrial',
  CANCEL_CONTRACT: '/api/Contract/CancelContract',
  GENERATE_DRAFT_CONTRACT: '/api/Contract/GenerateDraftContract',
  GET_PURCHASE_INVOICES: '/api/Contract/GetPurchaseInvoices',
  SUBSCRIPTION_CANCEL_REASONS: '/api/Tenant/SubscriptionCancelReasons',
  CANCEL_TRIAL: '/api/Tenant/CancelTrial',
  GET_AUDIT_INVOICES: '/api/Contract/GetInvoiceAudits',
  REACTIVATE_TENANT: '/api/Contract/ReactivateContract',
  GET_CONTRACT_SUMMARY: '/api/Contract/GetContractPointData',
  CREDIT_CONTROLS: '/api/Contract/PendingContractPayment',
  COLLECT_PAYMENT: '/api/Contract/RecollectPayment',
  XERO_CONTRACT_SUBSCRIPTION: '/api/XeroContractSubscription/InvoiceUrl',
  GET_LOCK_POINTS_LIST: 'api/JLPoint/GetLockPointsRequest',
};

export const CONTRACT_ENDPOINT = {
  EDIT_STRIPE_MAX_USERS: '/api/Contract/EditStripeMaxUsers',
  GET_USERS: '/api/Tenant/GetUsers',
  MARK_REMOVAL_LICENSE: '/api/Contract/MarkRemovalLicense',
  HAS_PENDING_INCREASE_REQUEST: '/api/Contract/HasPendingIncreaseRequest',
  GET_USER_LICENSE_AUDIT: '/api/Audit/GetUserLicenseAudit',
  GET_EDIT_USER_PREVIEW: '/api/Contract/GetEditUserPreview',
  GET_EDIT_USER_PREVIEW_WITH_DISCOUNT: '/api/Contract/GetEditUserPreviewWithDiscount',
  ACTIVE_DRAFT_CONTRACT: '/api/Contract/ActiveDraftContract',
  LOCKOUT_CONTRACT: '/api/Contract/LockOutContract',
  CHECK_MULTI_TENANT_COMPANY: '/api/Contract/CheckMultitenantCompany',
  CHANGE_PAYMENT_METHOD: '/api/Contract/ChangePaymentMethod',
  LOCK_POINTS: '/api/JLPoint/LockPoints',
  UNLOCK_POINTS: '/api/JLPoint/UnlockPoints',
};
